import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FilterPipeModule } from 'ngx-filter-pipe';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './modules/material.module';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MAT_DATE_LOCALE } from '@angular/material';

import { DataService } from './services/data.service';
import { AuthService } from './services/auth.service';

import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';

import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { ReportComponent } from './components/report/report.component';
import { ModerateApprovedComponent } from './components/moderateApproved/moderateApproved.component';
import { ModerateUnapprovedComponent } from './components/moderateUnapproved/moderateUnapproved.component';
import { ContactComponent } from './components/contact/contact.component';
import { FaqComponent } from './components/faq/faq.component';
import { LawsComponent } from './components/laws/laws.component';
import { StatusChangeDialogComponent } from './components/moderateApproved/modals/status-change-dialog/status-change-dialog.component';
import { DeleteDialogComponent } from './components/moderateUnapproved/modals/delete-dialog/delete-dialog.component';
import { UnapprovedStatusChangeDialogComponent } from './components/moderateUnapproved/modals/status-change-dialog/status-change-dialog.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { BlockUIModule } from 'ng-block-ui';

import { environment } from 'src/environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import { PrivacyComponent } from './components/privacy/privacy.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    HomeComponent,
    ReportComponent,
    ModerateApprovedComponent,
    ModerateUnapprovedComponent,
    ContactComponent,
    FaqComponent,
    LawsComponent,
    StatusChangeDialogComponent,
    DeleteDialogComponent,
    UnapprovedStatusChangeDialogComponent,
    PrivacyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      // please get your own API key here:
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
      apiKey: 'AIzaSyCJINjO2UhbvG55LG4jDorG6RmTv_rfTi8'
    }),
    AgmJsMarkerClustererModule,
    FilterPipeModule,
    ReactiveFormsModule,
    FormsModule,
    /* Firebase */
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    BlockUIModule.forRoot()
  ],
  entryComponents: [
    StatusChangeDialogComponent,
    DeleteDialogComponent,
    UnapprovedStatusChangeDialogComponent],
  providers: [
    DataService,
    AuthService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
