import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import 'firebase/firestore';
import { Plugins } from '@capacitor/core';
import { App } from '@capacitor/app';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'Nepravilnosti';
  mobileApp;

  constructor(
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute) {
      this.activatedRoute.queryParams.subscribe(params => {
        this.mobileApp = params['ma'];
      });
  }

  ngOnInit() {
    const defaultLanguage = environment.availableLanguages.filter(language => !!language.isDefault)[0];
    this.translateService.setDefaultLang(defaultLanguage.code);
  }

}

// enables hardware back button
document.addEventListener('backbutton', function(){
  window.history.back();
});