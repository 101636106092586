import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { ReportComponent } from './components/report/report.component';
import { ModerateApprovedComponent } from './components/moderateApproved/moderateApproved.component';
import { ModerateUnapprovedComponent } from './components/moderateUnapproved/moderateUnapproved.component';
import { LoginComponent } from './components/login/login.component';

import { ContactComponent } from './components/contact/contact.component';
import { FaqComponent } from './components/faq/faq.component';
import { LawsComponent } from './components/laws/laws.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';


const appRoutes: Routes = [
  { path: '',   redirectTo: '/nepravilnosti', pathMatch: 'full' },
  { path: 'nepravilnosti', component: HomeComponent },
  { path: 'nepravilnosti/:id', component: HomeComponent },
  { path: 'odobrene', component: ModerateApprovedComponent, canActivate: [AngularFireAuthGuard] },
  { path: 'neodobrene', component: ModerateUnapprovedComponent, canActivate: [AngularFireAuthGuard] },
  { path: 'prijavi', component: ReportComponent },
  { path: 'kontakt', component: ContactComponent },
  { path: 'info', component: FaqComponent },
  { path: 'zakoni', component: LawsComponent },
  { path: 'privatnost', component: PrivacyComponent },
  { path: 'auth', component: LoginComponent }
];

// todo LUKA
// , canActivate: [AngularFireAuthGuard] -  dodati ovo na rutu za zastitu

// store token on sign in application storage
// check for app storage for token, if not present, signout
// if present send it with request in Authorization: [token]

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
