// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  availableLanguages: [{
    code: 'bg',
    name: 'Български',
    isDefault: true
  }, {
    code: 'en',
    name: 'English'
  }],
  firebaseConfig: {
    apiKey: "AIzaSyCVkyBDKM343mVAslJHdGiee8tm6T6FrCo",
    authDomain: "ferislobodniizbori.firebaseapp.com",
    databaseURL: "https://ferislobodniizbori.firebaseio.com",
    projectId: "ferislobodniizbori",
    storageBucket: "ferislobodniizbori.appspot.com",
    messagingSenderId: "679248520987",
    appId: "1:679248520987:web:19829e4efd4db6e90155be",
    measurementId: "G-JRH7S111WE"
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
