<h2 mat-dialog-title>Promena statusa</h2>
<mat-dialog-content class="mat-typography">
    <p>
      Ovom akcijom vraćate nepravilnost u neodobrene. <br>
      Da li ste sigurni?
    </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Odustani</button>
  <button mat-button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial (click)="statusChange()">Da</button>
</mat-dialog-actions>
