import { Component, OnInit, NgZone, ChangeDetectorRef } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email: FormControl;
  password: FormControl;
  logedIn;

  public loginInfo = {
    email: '',
    password: ''
  }; // initialize to empty user.

  constructor(public fb: AngularFireAuth,
              private authService: AuthService,
              private snackBar: MatSnackBar,
              private ngZone: NgZone) {

              }

  ngOnInit() {
    this.email = new FormControl('');
    this.password = new FormControl('');
    this.logedIn = this.authService.loggedIn;
    this.authService.loggedInChange.subscribe((resp) => this.logedIn = resp);

  }

  onLoginubmit() {
      this.authService.login(this.email.value, this.password.value);
  }

  logout() {
    this.authService.logout();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'error'
    });
  }

}
