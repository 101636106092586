<div class="login-form-wrapper">
  <form #loginForm="ngForm" (ngSubmit)="onLoginubmit()">
      <mat-card class="login-form">
        <mat-card-title>Uloguj se</mat-card-title>
        <mat-card-content>
            <div class="form__vertical" [ngClass]="{'logged-in': logedIn}">
                <mat-form-field appearance="fill">
                    <mat-label>E-mail</mat-label>
                    <input matInput type='text' [formControl]="email" name="email" id="email" required>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Lozinka</mat-label>
                    <input matInput type='password' [formControl]="password" name="password" id="password" required>
                </mat-form-field>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button color="primary" [disabled]="logedIn">Uloguj se</button>
            <button mat-raised-button color="primary" type="button" [disabled]="!logedIn" (click)="logout()">Izloguj se</button>
        </mat-card-actions>
    </mat-card>
  </form>

</div>




