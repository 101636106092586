<mat-toolbar color="primary">
  <mat-toolbar-row>

    <img src="assets/images/logoNepravilnosti.png" class="logo" />

    <span>Fer i slobodni izbori</span>

    <span [matMenuTriggerFor]="yearMenu" class="header-menu-item hide-on-mobile" [ngClass]="{'active': irregularitiesActive == true}">
      <span>Nepravilnosti {{electionYear}}</span>
      <mat-icon>arrow_drop_down</mat-icon>
    </span>
    <mat-menu #yearMenu="matMenu">
      <button mat-menu-item [routerLink]="['/nepravilnosti']" [queryParams]="{ year: '2024'}" queryParamsHandling="merge" (click)="selectElectionYear('2024')">
        <span>Nepravilnosti 2024</span>
      </button>
      <button mat-menu-item [routerLink]="['/nepravilnosti']" [queryParams]="{ year: '2022'}" queryParamsHandling="merge" (click)="selectElectionYear('2022')">
        <span>Nepravilnosti 2022</span>
      </button>
      <button mat-menu-item [routerLink]="['/nepravilnosti']" [queryParams]="{ year: '2020'}" queryParamsHandling="merge" (click)="selectElectionYear('2020')">
        <span>Nepravilnosti 2020</span>
      </button>
    </mat-menu>

    <a href="#" [routerLink]="['/prijavi']" [routerLinkActive]="['active']" queryParamsHandling="merge" class="header-menu-item hide-on-mobile">Prijavi nepravilnost</a>
    <a href="#" [routerLink]="['/kontakt']" [routerLinkActive]="['active']" queryParamsHandling="merge" class="header-menu-item hide-on-mobile">Kontakt</a>
    <a href="https://1od5miliona.rs/" target="_blank" class="header-menu-item hide-on-mobile">#1od5miliona</a>

    <span class="example-spacer"></span>

    <button mat-icon-button [matMenuTriggerFor]="languageMenu" class="hide-on-mobile">
      <mat-icon>info</mat-icon>
    </button>

    <button mat-icon-button [matMenuTriggerFor]="navigationMenu" class="hide-on-mobile" *ngIf="logedIn">
      <mat-icon>menu</mat-icon>
    </button>

    <button mat-icon-button (click)="toggleMobileMenu()" class="mobile-hamburger hide-on-desktop">
      <mat-icon>menu</mat-icon>
    </button>

    <mat-menu #navigationMenu="matMenu">
      <button mat-menu-item [routerLink]="['/auth']" queryParamsHandling="merge">
        <span *ngIf="logedIn">Izloguj se</span>
        <span *ngIf="!logedIn">Uloguj se</span>
      </button>
      <button mat-menu-item [routerLink]="['/odobrene']" queryParamsHandling="merge">
        <span>Odobrene</span>
      </button>
      <button mat-menu-item [routerLink]="['/neodobrene']" queryParamsHandling="merge">
        <span>Neodobrene</span>
      </button>
    </mat-menu>

    <mat-menu #languageMenu="matMenu">
      <button mat-menu-item [routerLink]="['/info']" queryParamsHandling="merge">
        <span>O aplikaciji</span>
      </button>
      <button mat-menu-item [routerLink]="['/zakoni']" queryParamsHandling="merge">
        <span>Izborni zakon Republike Srbije</span>
      </button>
      <button mat-menu-item [routerLink]="['/privatnost']" queryParamsHandling="merge">
        <span>Politika privatnosti</span>
      </button>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>


<div *ngIf="mobileMenuOpen" class="mobile-menu hide-on-desktop">
  <img src="assets/images/logoNepravilnosti.png" class="logo" />
  <ul>
    <li><a href="#" [routerLink]="['/nepravilnosti']" [routerLinkActive]="['active']" class="header-menu-item" queryParamsHandling="merge" (click)="closeMobileMenu()">Nepravilnosti</a></li>
    <li class="sub-item"><a href="#" [routerLink]="['/nepravilnosti']" [routerLinkActive]="['active']" class="header-menu-item" queryParamsHandling="merge" [queryParams]="{ year: '2024'}" (click)="closeMobileMenu(); selectElectionYear('2024')">2024</a></li>
    <li class="sub-item"><a href="#" [routerLink]="['/nepravilnosti']" [routerLinkActive]="['active']" class="header-menu-item" queryParamsHandling="merge" [queryParams]="{ year: '2022'}" (click)="closeMobileMenu(); selectElectionYear('2022')">2022</a></li>
    <li class="sub-item"><a href="#" [routerLink]="['/nepravilnosti']" [routerLinkActive]="['active']" class="header-menu-item" queryParamsHandling="merge" [queryParams]="{ year: '2020'}" (click)="closeMobileMenu(); selectElectionYear('2020')">2020</a></li>
    <li><a href="#" [routerLink]="['/prijavi']" [routerLinkActive]="['active']" class="header-menu-item" queryParamsHandling="merge" (click)="closeMobileMenu()">Prijavi nepravilnost</a></li>
    <li><a href="#" [routerLink]="['/kontakt']" [routerLinkActive]="['active']" class="header-menu-item" queryParamsHandling="merge" (click)="closeMobileMenu()">Kontakt</a></li>
    <li><a href="#" [routerLink]="['/info']" [routerLinkActive]="['active']" class="header-menu-item" queryParamsHandling="merge" (click)="closeMobileMenu()">O aplikaciji</a></li>
    <li><a href="#" [routerLink]="['/zakoni']" [routerLinkActive]="['active']" class="header-menu-item" queryParamsHandling="merge" (click)="closeMobileMenu()">Izborni zakon Republike Srbije</a></li>
    <li><a href="#" [routerLink]="['/privatnost']" [routerLinkActive]="['active']" class="header-menu-item" queryParamsHandling="merge" (click)="closeMobileMenu()">Politika privatnosti</a></li>
    <li><a href="https://1od5miliona.rs/" target="_blank" class="header-menu-item">#1od5miliona</a></li>
  </ul>
</div>
