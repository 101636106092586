import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Geolocation } from '@capacitor/geolocation';

@Component({
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  today = new Date();
  filesLength = 0;
  max_files = 10;
  showFileSizeWarning = false;
  showFileCountWarning = false;
  androidCoordinates: any;

  public reportInfo = {
    description: '',
    title: '',
    location: {
      geoLocation: {
        lat: null,
        lng: null
      },
     municipality: '',
     address: '',
     votingUnitNumber: ''
    },
    reportedAt: this.today,
    attachmentUrl: '',
    contact: {
      email: '',
      name: '',
      phoneNumber: ''
    },
    attachments: '',
    link: '',
    imageUrl: ''
  };

  fileToUpload: File = null;
  selectedID = null;
  serverMessage = '';
  currLat: number;
  currLng: number;
  uploadedFiles: Array<any> = new Array<any>();
  // filesMap: Map<String, File> = new Map<String, File>();
  http: any;

  constructor(private dataservice: DataService,
              private snackBar: MatSnackBar) { }


  ngOnInit() {
    this.getCurrentLocation();
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    console.log(this.fileToUpload);
  }

  onReportSubmit() {
    const formData = this.getFilesFormData();
    this.dataservice.postReport(this.reportInfo, formData).subscribe((resp) => {
      console.log(resp);
      this.selectedID = resp.reportId;
      this.serverMessage = resp.message;
      this.openSnackBar('Uspešno ste poslali nepravilnost.', '✕');
      this.filesLength = 0;
      this.reportInfo = {
        description: '',
        title: '',
        location: {
          geoLocation: {
            lat: '',
            lng: ''
          },
         municipality: '',
         address: '',
         votingUnitNumber: ''
        },
        reportedAt: this.today,
        attachmentUrl: '',
        contact: {
          email: '',
          name: '',
          phoneNumber: ''
        },
        attachments: '',
        link: '',
        imageUrl: ''
      };
     });
  }

  getCurrentLocation() {   
    // Geolocation.getCurrentPosition().then(data => {
    //   this.androidCoordinates = {
    //     latitude: data.coords.latitude,
    //     longitude: data.coords.longitude
    //   };
    //   this.reportInfo.location.geoLocation.lat = this.androidCoordinates.latitude;
    //   this.reportInfo.location.geoLocation.lng = this.androidCoordinates.longitude;
    // }).catch(err => {
    //   console.error(err);
    // });
    
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.reportInfo.location.geoLocation.lat = position.coords.latitude;
        this.reportInfo.location.geoLocation.lng = position.coords.longitude;
        console.log(this.reportInfo.location.geoLocation.lat, this.reportInfo.location.geoLocation.lng);
      });
    } else {
      this.openSnackBar('Vaš pregledač ne podržava geolokaciju', '✕');
    }
  }

  closeWarnings() {
    this.showFileSizeWarning = false;
    this.showFileCountWarning = false;
  }

  fileChange(element) {
    console.log(element.target.files);
    this.filesLength = element.target.files.length;
    this.closeWarnings();
    if (!this.filesLength) {
      console.log('no files selected', element.target);
      return;
    }

    if (this.filesLength > this.max_files) {
      console.log('cannot select more than ' + this.max_files + ' files');
      this.filesLength = this.max_files;
      this.showFileCountWarning = true;
      element.target.value = '';
      this.filesLength = element.target.files.length;
    }

    // this.uploadedFiles = element.target.files.slice(0,filesLength);
    for (let i = 0; i < this.filesLength; i++) {
      const file = element.target.files[i];
      this.uploadedFiles[i] = file;
      if (file.size > 52428800) {
        this.showFileSizeWarning = true;
        element.target.value = '';
        this.filesLength = element.target.files.length;
        console.log(element.target.files);
      }

    }
    // this.filesMap.set(element.target.id, element.target.files[0]);
  }

  getFilesFormData() {
    let formData = new FormData();
    console.log('formdata', formData.append);
    const maxFiles = 10;
    let countFiles = 0;
    this.uploadedFiles.forEach((item, index) => {
      formData.append("files[]", item, item.name);
      countFiles++;
    });
    // this.filesMap.forEach((item: any, key: String) => {
    //   if(key && countFiles < maxFiles) {
    //     formData.append("files[]", item, item.name);
    //     // console.log('formdata ' + countFiles, formData);
    //     countFiles++;
    //   }
    // })
    if (countFiles === 0) {
      return null;
    }
    return formData;
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'success'
    });
  }

}
