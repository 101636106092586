<div class="moderate-list">
  <div class="moderate-list__button">
    <button mat-raised-button color="primary" (click)="createNewIrregularity()">+ Nepravilnost</button>
  </div>
  <ul class="moderate-list-inner">
      <li *ngFor="let item of approvedIrregularities | filterBy: filters; let i = index" [attr.data-index]="i" class="moderate-item" (click)="selectIrregularity(item.id);" [ngClass]="{'active': selectedId === item.id}">
          <span class="moderate-item__index">{{i + 1}}</span>
          <span class="moderate-item__title">
            <h4>{{item.title}}</h4>
            <span class="location-info">
              <span>{{item.location.municipality}}</span>
              <span *ngIf="item.location.municipality && item.location.address"> - </span>
              <span>{{item.location.address}}</span>
            </span>
            <div class="flag-status flag-status-approved" *ngIf="item.confirmed && item.draft">
              <mat-icon>flag</mat-icon>
              <span>Odobrena</span>
            </div>
            <div class="flag-status flag-status-unfinished" *ngIf="!item.confirmed && item.draft">
              <mat-icon>flag</mat-icon>
              <span>Draft</span>
            </div>
          </span>
          <!-- <span class="moderate-item__actions">
            <button mat-raised-button color="primary" mat-button [matMenuTriggerFor]="menu">
              <mat-icon>flag</mat-icon>
              <mat-icon class="flag-select">expand_more</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item class="flag-status flag-status-approved" (click)="setIrregularityStatus('approved')">
                <mat-icon>flag</mat-icon>
                <span>Odobrena nepravilnost</span>
              </button>
              <button mat-menu-item class="flag-status flag-status-unfinished" (click)="setIrregularityStatus('draft')">
                <mat-icon>flag</mat-icon>
                <span>Draft</span>
              </button>
              <button mat-menu-item class="flag-status flag-status-unapproved" (click)="setIrregularityStatus('unapproved')">
                <mat-icon>flag</mat-icon>
                <span>Neodobrena nepravilnost</span>
              </button>
            </mat-menu>
          </span> -->
      </li>
      <li *ngIf="(approvedIrregularities | filterBy: filters).length === 0" class="nothing-found">Ni jedna nepravilnost ne odgovara pretrazi.</li>
  </ul>
</div>

<div class="moderate-detail">
  <div class="moderate-detail__actions">
    <input type="text" placeholder="Pretraži" [(ngModel)]="searchTerm" (keyup)="filterByTitle(searchTerm)">
    <mat-icon class="search-icon">search</mat-icon>
  </div>
  <div class="moderate-detail__inner">
    <mat-card class="moderate-irregularity-form" *ngIf="!selectedMode">
      <mat-card-title>Nijedna nepravilnost nije izabrana</mat-card-title>
      <mat-card-content>
        Izaberite nepravilnost na kojoj želite da radite.
      </mat-card-content>
    </mat-card>

    <form #approvedForm="ngForm" (ngSubmit)="onNewIrregularitySubmit()"  *ngIf="selectedMode == 'createNewIrregularity'">
      <mat-card class="moderate-irregularity-form">
        <mat-card-title>Nova Nepravilnost</mat-card-title>
        <mat-card-content>

              <div class="form-fields-wrapper">
                <div class="form-field-100">
                  <mat-form-field appearance="fill">
                    <mat-label>Nepravilnost</mat-label>
                    <input matInput type='text' [(ngModel)]="newInfo.title" name="title" required>
                  </mat-form-field>
                </div>
                <div class="form-field-100">
                  <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>Opišite nepravilnost</mat-label>
                    <textarea matInput rows= "6" [(ngModel)]="newInfo.description" name="description" required></textarea>
                  </mat-form-field>
                </div>
              </div>

              <div class="form-fields-wrapper">
                <div class="form-field-50">
                  <mat-form-field appearance="fill">
                    <mat-label>Opština</mat-label>
                    <input matInput type='text' [(ngModel)]="newInfo.location.municipality" name="municipality" required>
                  </mat-form-field>
                </div>
                <div class="form-field-50">
                  <mat-form-field appearance="fill">
                    <mat-label>Adresa</mat-label>
                    <input matInput type='text' [(ngModel)]="newInfo.location.address" name="address" required>
                  </mat-form-field>
                </div>
              </div>

              <div class="form-fields-wrapper">
                <div class="form-field-50">
                  <mat-form-field appearance="fill">
                    <mat-label>Broj biračkog mesta</mat-label>
                    <input matInput type='text' [(ngModel)]="newInfo.location.votingUnitNumber" name="votingUnitNumber">
                  </mat-form-field>
                </div>
                <div class="form-field-25">
                  <mat-form-field appearance="fill">
                    <mat-label>Geografska širina</mat-label>
                    <input matInput type='text' [(ngModel)]="newInfo.location.geoLocation.lat" name="lat" required>
                  </mat-form-field>
                </div>
                <div class="form-field-25">
                  <mat-form-field appearance="fill">
                    <mat-label>Geografska dužina</mat-label>
                    <input matInput type='text' [(ngModel)]="newInfo.location.geoLocation.lng" name="lng" required>
                  </mat-form-field>
                </div>
              </div>

              <a href="https://www.latlong.net/" target="_blank" class="location-convertor">Konvertor adrese u koordinate</a>
              <a href="https://www.latlong.net/Show-Latitude-Longitude.html" target="_blank" class="location-convertor">Konvertor koordinata u adresu</a>

              <div class="form-fields-wrapper">
                <div class="form-field-100">
                  <mat-form-field appearance="fill">
                    <mat-label>Datum nepravilnosti</mat-label>
                    <input matInput [matDatepicker]="picker" [(ngModel)]="newInfo.reportedAt" name="reportedAt">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="form-field-100">
                  <mat-form-field appearance="fill">
                    <mat-label>Link / izvor</mat-label>
                    <input matInput type='text' [(ngModel)]="newInfo.link" name="link">
                  </mat-form-field>
                </div>
              </div>

              <div class="form-fields-wrapper">
                <div class="form-field-100">
                  <mat-form-field appearance="fill">
                    <mat-label>Ime i prezime</mat-label>
                    <input matInput type='text' [(ngModel)]="newInfo.contact.name" name="name">
                  </mat-form-field>
                </div>
                <div class="form-field-50">
                  <mat-form-field appearance="fill">
                    <mat-label>E-mail</mat-label>
                    <input matInput type='text' [(ngModel)]="newInfo.contact.email" name="email">
                  </mat-form-field>
                </div>
                <div class="form-field-50">
                  <mat-form-field appearance="fill">
                    <mat-label>Telefon</mat-label>
                    <input matInput type='text' [(ngModel)]="newInfo.contact.phoneNumber" name="phoneNumber">
                  </mat-form-field>
                </div>
              </div>

              <div class="file-upload">
                <input (change)="fileChange($event)"
                  type="file"
                  class="custom-file-input file-upload__input"
                  multiple="true"
                  accept="image/*,audio/*,video/*,.pdf"
                  id="fileUploadSomething"
                  aria-describedby="inputGroupFileAddon01" />
                  <label for="fileUploadSomething" class="file-upload__label">Priložite slike, video ili dokumenta</label>
              </div>

              <span *ngIf="filesLength == 1" class="files-selected">{{filesLength}} fajl izabran.</span>
              <span *ngIf="filesLength > 1 && filesLength < 5" class="files-selected">{{filesLength}} fajla izabrana.</span>
              <span *ngIf="filesLength > 4" class="files-selected">{{filesLength}} fajlova izabrano.</span>

              <!-- if file too big -->

              <div class="warning file-size" *ngIf="showFileSizeWarning">
                <mat-icon>warning</mat-icon>
                <mat-icon class="close-icon" (click)="closeWarnings()">close</mat-icon>
                <div class="warning-body">
                  <p> Podigli ste preveliki fajl da bismo ga sačuvali na našem serveru.</p>
                  <p> Ili ga smanjite, ili podignite ga na servis za hosting fajlova kao što su:</p>
                  <a href="https://wetransfer.com/" target="_blank">Wetransfer</a>
                  <a href="https://www.google.com/drive/" target="_blank">Google Drive</a>
                  <a href="https://www.dropbox.com/" target="_blank">Dropbox</a>
                </div>
              </div>

              <div class="warning file-count" *ngIf="showFileCountWarning">
                <mat-icon>warning</mat-icon>
                <mat-icon class="close-icon" (click)="closeWarnings()">close</mat-icon>
                <div class="warning-body">
                  <p> Maksimalni broj fajlova je 10.</p>
                </div>
              </div>

            </mat-card-content>
            <mat-card-actions>
                <button mat-raised-button color="primary" [disabled]="!approvedForm.valid">Sačuvaj</button>
            </mat-card-actions>
    </mat-card>
  </form>

  <form #approvedForm="ngForm" (ngSubmit)="onApprovedSubmit()"  *ngIf="selectedMode == 'viewAndEditIrregularity'">
      <mat-card class="moderate-irregularity-form">
        <mat-card-title>{{approvedInfo.title}}</mat-card-title>
        <mat-card-content>

              <div class="form-fields-wrapper">
                <div class="form-field-100">
                  <mat-form-field appearance="fill">
                    <mat-label>Nepravilnost</mat-label>
                    <input matInput type='text' [(ngModel)]="approvedInfo.title" name="title" required>
                  </mat-form-field>
                </div>
                <div class="form-field-100">
                  <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>Opišite nepravilnost</mat-label>
                    <textarea matInput rows= "6" [(ngModel)]="approvedInfo.description" name="description" required></textarea>
                  </mat-form-field>
                </div>
              </div>

              <div class="form-fields-wrapper">
                <div class="form-field-50">
                  <mat-form-field appearance="fill">
                    <mat-label>Opština</mat-label>
                    <input matInput type='text' [(ngModel)]="approvedInfo.location.municipality" name="municipality" required>
                  </mat-form-field>
                </div>
                <div class="form-field-50">
                  <mat-form-field appearance="fill">
                    <mat-label>Adresa</mat-label>
                    <input matInput type='text' [(ngModel)]="approvedInfo.location.address" name="address" required>
                  </mat-form-field>
                </div>
              </div>

              <div class="form-fields-wrapper">
                <div class="form-field-50">
                  <mat-form-field appearance="fill">
                    <mat-label>Broj biračkog mesta</mat-label>
                    <input matInput type='text' [(ngModel)]="approvedInfo.location.votingUnitNumber" name="votingUnitNumber">
                  </mat-form-field>
                </div>
                <div class="form-field-25">
                  <mat-form-field appearance="fill">
                    <mat-label>Geografska širina</mat-label>
                    <input matInput type='text' [(ngModel)]="approvedInfo.location.geoLocation.lat" name="lat" required>
                  </mat-form-field>
                </div>
                <div class="form-field-25">
                  <mat-form-field appearance="fill">
                    <mat-label>Geografska dužina</mat-label>
                    <input matInput type='text' [(ngModel)]="approvedInfo.location.geoLocation.lng" name="lng" required>
                  </mat-form-field>
                </div>
              </div>

              <a href="https://www.latlong.net/" target="_blank" class="location-convertor">Konvertor adrese u koordinate</a>
              <a href="https://www.latlong.net/Show-Latitude-Longitude.html" target="_blank" class="location-convertor">Konvertor koordinata u adresu</a>

              <div class="form-fields-wrapper">
                <div class="form-field-100">
                  <mat-form-field appearance="fill">
                    <mat-label>Datum nepravilnosti</mat-label>
                    <input matInput [matDatepicker]="picker" [(ngModel)]="approvedInfo.reportedAt" name="reportedAt">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="form-field-100">
                  <mat-form-field appearance="fill">
                    <mat-label>Link / izvor</mat-label>
                    <input matInput type='text' [(ngModel)]="approvedInfo.link" name="link">
                  </mat-form-field>
                </div>
              </div>

              <div class="form-fields-wrapper">
                <div class="form-field-100">
                  <mat-form-field appearance="fill">
                    <mat-label>Ime i prezime</mat-label>
                    <input matInput type='text' [(ngModel)]="approvedInfo.contact.name" name="name">
                  </mat-form-field>
                </div>
                <div class="form-field-50">
                  <mat-form-field appearance="fill">
                    <mat-label>E-mail</mat-label>
                    <input matInput type='text' [(ngModel)]="approvedInfo.contact.email" name="email">
                  </mat-form-field>
                </div>
                <div class="form-field-50">
                  <mat-form-field appearance="fill">
                    <mat-label>Telefon</mat-label>
                    <input matInput type='text' [(ngModel)]="approvedInfo.contact.phoneNumber" name="phoneNumber">
                  </mat-form-field>
                </div>
              </div>

              <div class="moderate-detail__attatchments" *ngIf="approvedInfo.attachments && approvedInfo.attachments.length > 0">
                <p><span>Priloženo:</span></p>
                <div class="attatchment">
                  <span *ngFor="let attatchment of approvedInfo.attachments; let a = index">
                    <a href="{{attatchment}}" target="_blank">{{attatchment}}</a>
                  </span>
                </div>
              </div>

            </mat-card-content>
            <mat-card-actions>
                <button mat-raised-button color="primary" [disabled]="!approvedForm.valid">Sačuvaj</button>
            </mat-card-actions>
      </mat-card>
    </form>

  </div>
</div>
