import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VERSION, MatDialog, MatDialogRef } from '@angular/material';
import { DeleteDialogComponent } from './modals/delete-dialog/delete-dialog.component';
import { UnapprovedStatusChangeDialogComponent } from './modals/status-change-dialog/status-change-dialog.component';
import { DataService } from 'src/app/services/data.service';

@Component({
  templateUrl: './moderateUnapproved.component.html',
  styleUrls: ['./moderateUnapproved.component.scss']
})
export class ModerateUnapprovedComponent implements OnInit {

  deleteDialogRef: MatDialogRef<DeleteDialogComponent>;
  statusChangeDialogRef: MatDialogRef<UnapprovedStatusChangeDialogComponent>;
  searchTerm = '';
  filters: any = {};

  unApprovedIrregularities: any = [];
  selectedIndex: number = null;
  selectedId: any = null;
  irregularityOpen = false;
  selectedMode: any = null;
  newStatus;

  public unApprovedInfo = {
    description: '',
    title: '',
    location: {
      geoLocation: {
        lat: null,
        lng: null
      },
     municipality: '',
     address: '',
     votingUnitNumber: ''
    },
    reportedAt: '',
    attachment: [],
    attachmentUrl: '',
    contact: {
      email: '',
      name: '',
      phoneNumber: ''
    },
    attachments: '',
    link: '',
    imageUrl: '',
    newstatus: ''
  };

  constructor(private snackBar: MatSnackBar,
              private dialog: MatDialog,
              private dataservice: DataService) { }

  ngOnInit() {
    this.getIrregularities();
  }

  getIrregularities() {
    this.dataservice.getAdminReports({draft: false, confirmed: false}).subscribe((resp) => {
      this.unApprovedIrregularities = resp;
      console.log(resp);
    });
  }

  selectIrregularity(id) {
    this.selectedId = id;
    this.unApprovedInfo = this.unApprovedIrregularities.find(x => x.id === this.selectedId);
    this.selectedMode = 'viewAndEditIrregularity';
    console.log(this.unApprovedInfo);
  }

  onUnApprovedSubmit() {
    console.log(this.unApprovedInfo);
    this.openSnackBar('Nepravilnost je sačuvana.', '✕');
  }

  setIrregularityStatus(status) {
    this.unApprovedInfo.newstatus = status;
    this.openStatusChangeDialog();
    // approved draft unapproved
  }

  openStatusChangeDialog() {
    this.statusChangeDialogRef = this.dialog.open(UnapprovedStatusChangeDialogComponent, {
      data:  this.unApprovedInfo
    });
    this.statusChangeDialogRef.afterClosed().subscribe(
      data => this.getIrregularities()
    );
  }

  openDeleteDialog() {
    this.deleteDialogRef = this.dialog.open(DeleteDialogComponent, {
      data: this.unApprovedInfo
    });
    this.deleteDialogRef.afterClosed().subscribe(
      data => this.getIrregularities()
    );
  }

  deleteIrregularity() {
    this.openDeleteDialog();
  }

  filterByTitle(searchTerm) {
    this.searchTerm = searchTerm;
    this.selectedMode = null;
    this.filters = {
      title: {
        $or: [this.searchTerm]
      }
    };
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'success'
    });
  }

}
