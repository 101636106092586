import { Component, OnInit, Directive, ViewChild, OnDestroy } from '@angular/core';
import { MouseEvent } from '@agm/core';
import { DataService } from 'src/app/services/data.service';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  @ViewChild('sidenav', {static: true}) public sidenav;

  // approvedIrregularities: any = [];

  isMobileApp;
  mobileAppString = '';
  electionYear;
  filterNavigationEvent;
  showNoIrregularitiesNotice = false;

  approvedIrregularities: any = [
    {
       id: 'EWRk0ZVnD4BNKpIEbN5v',
       location: {
        geoLocation: {
          lat: 44.809590,
          lng: 20.462570
         },
        municipality: 'Stari Grad',
        address: 'RIK, Kralja Milana 14',
       },
       title: 'Primer nepravilnosti',
       description: 'Izborne nepravilnosti jesu povrede zakona kojima se reguliše izborni proces, a koje za cilj imaju manipulisanje biračima, krađu glasova i prekrajanje volje građana. Beležimo sve: šta se dešava tokom kampanje pre izbornog dana, zatim na sam dan glasanja, i u periodu nakon održavanja izbora.',
       reportedBy: '#1od5miliona',
       dateReported: 'Thu Feb 20 2020 00:00:00 GMT+0100 (Central European Standard Time)',
       attachmentUrl: ['https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'],
       link: 'https://1od5miliona.rs/',
       imageUrl: ''
    }
 ];

id: any = '';

 selectedIrregularity: any = {
  id: '',
  description: '',
  title: '',
  location: {
    geoLocation: {
      lat: '',
      lng: ''
    },
   municipality: '',
   address: '',
   votingUnitNumber: ''
  },
  date: '',
  attachmentUrl: '',
  reportedBy: '',
  link: '',
  imageUrl: ''
 };

  // google maps zoom level
  zoom = 8;

  // initial center position for the map
  lat = 44.786568;
  lng = 20.448921;

  styles = [
    {
        featureType: 'administrative',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#BD1E2B'
            }
        ]
    },
    {
        featureType: 'administrative.province',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [
            {
                saturation: -100
            },
            {
                lightness: 65
            },
            {
                visibility: 'on'
            }
        ]
    },
    {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
            {
                saturation: -100
            },
            {
                lightness: 51
            },
            {
                visibility: 'simplified'
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'all',
        stylers: [
            {
                saturation: -100
            },
            {
                visibility: 'simplified'
            }
        ]
    },
    {
        featureType: 'road.arterial',
        elementType: 'all',
        stylers: [
            {
                saturation: -100
            },
            {
                lightness: 30
            },
            {
                visibility: 'on'
            }
        ]
    },
    {
        featureType: 'road.local',
        elementType: 'all',
        stylers: [
            {
                saturation: -100
            },
            {
                lightness: 40
            },
            {
                visibility: 'on'
            }
        ]
    },
    {
        featureType: 'transit',
        elementType: 'all',
        stylers: [
            {
                saturation: -100
            },
            {
                visibility: 'simplified'
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
            {
                hue: '#ffff00'
            },
            {
                lightness: -25
            },
            {
                saturation: -97
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'on'
            },
            {
                lightness: -25
            },
            {
                saturation: -100
            }
        ]
    }
];

  constructor(
    private dataservice: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location) {
      this.isMobileApp = this.route.snapshot.queryParamMap.get('ma') || false;
      this.onRouteChange();
  }

  ngOnInit() {

  }

  onRouteChange() {
    this.filterNavigationEvent = this.router.events.pipe(filter((e) => e instanceof NavigationEnd))
    .subscribe((s: NavigationEnd) => {
      this.electionYear = this.route.snapshot.queryParamMap.get('year') || '2024';
      this.getHomeReports(this.electionYear);
      this.sidenav.close();
    });
  }

  getHomeReports(electionYear) {
    let electionYearQueryParameter =  '?year=' + electionYear;
    this.location.go('/nepravilnosti' + electionYearQueryParameter);

    this.dataservice.getReports(electionYear).subscribe((resp) => {
      this.id = this.route.snapshot.paramMap.get('id');
      this.approvedIrregularities = resp;
      if (resp.length === 0) {
        this.showNoIrregularitiesNotice = true;
      } else {
        this.showNoIrregularitiesNotice = false;
      }
      // console.log(resp);
      const irregularityFromUrl = this.approvedIrregularities.find(x => x.id === this.id);
      if (irregularityFromUrl) {
        this.selectedIrregularity = irregularityFromUrl;
        this.location.go('/nepravilnosti/' + this.selectedIrregularity.id + electionYearQueryParameter);
        this.sidenav.open();
      }
     });
  }

  clickedMarker(label: string, index: number) {
    let electionYearQueryParameter =  '?year=' + this.electionYear;
    this.location.go('/nepravilnosti' + electionYearQueryParameter);
    this.selectedIrregularity = this.approvedIrregularities[index];
    this.sidenav.open();
    console.log(this.selectedIrregularity);
    this.route.queryParams.subscribe(params => {
       // tslint:disable-next-line:no-string-literal
      if (params['ma'] === 'true') {
        this.mobileAppString = '?ma=true';
      } else {
        this.mobileAppString = '';
      }
    });

    this.location.go('/nepravilnosti/' + this.selectedIrregularity.id + this.mobileAppString + electionYearQueryParameter);
    // console.log(`clicked the marker: ${label || index}`)
  }

  resetMap() {
    this.route.queryParams.subscribe(params => {
      // tslint:disable-next-line:no-string-literal
      if (params['ma'] === 'true') {
        this.mobileAppString = '?ma=true';
      } else {
        this.mobileAppString = '';
      }
    });
    this.location.go('/nepravilnosti' + this.mobileAppString);
    this.sidenav.close();
  }

  mapClicked($event: MouseEvent) {
    // this.markers.push({
    //   lat: $event.coords.lat,
    //   lng: $event.coords.lng,
    //   draggable: true
    // });
    console.log($event);
  }

  markerDragEnd(m: marker, $event: MouseEvent) {
    console.log('dragEnd', m, $event);
  }

  toNumber(stringToNumberValue) {
    return Number(stringToNumberValue);
  }

  ngOnDestroy() {
    this.filterNavigationEvent.unsubscribe();
  }

}

// just an interface for type safety.
// tslint:disable-next-line:class-name
interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
