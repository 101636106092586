<div class="moderate-list moderate-list__unapproved">
  <div class="moderate-list__button">
    <button mat-raised-button color="primary">+ Nepravilnost</button>
  </div>
  <ul class="moderate-list-inner">
      <li *ngFor="let item of unApprovedIrregularities | filterBy: filters; let i = index" [attr.data-index]="i" class="moderate-item" (click)="selectIrregularity(item.id);" [ngClass]="{'active': selectedId === item.id}">
          <span class="moderate-item__index">{{i + 1}}</span>
          <span class="moderate-item__title">
            <h4>{{item.title}}</h4>
            <span class="location-info">
              <span>{{item.location.municipality}}</span>
              <span *ngIf="item.location.municipality && item.location.address"> - </span>
              <span>{{item.location.address}}</span>
            </span>
          </span>
          <!-- <span class="moderate-item__actions">
            <button mat-raised-button color="primary" (click)="deleteIrregularity()"><mat-icon>delete</mat-icon></button>
            <button mat-raised-button color="primary" (click)="setIrregularityStatus('draft')"><mat-icon>check</mat-icon></button>
          </span> -->
      </li>
      <li *ngIf="(unApprovedIrregularities | filterBy: filters).length === 0" class="nothing-found">Ni jedna nepravilnost ne odgovara pretrazi.</li>
  </ul>
</div>

<div class="moderate-detail">

  <div class="moderate-detail__actions">
    <input type="text" placeholder="Pretraži" [(ngModel)]="searchTerm"  (keyup)="filterByTitle(searchTerm)">
    <mat-icon class="search-icon">search</mat-icon>
  </div>

  <div class="moderate-detail__inner">

    <mat-card class="moderate-irregularity-form" *ngIf="!selectedMode">
      <mat-card-title>Nijedna nepravilnost nije izabrana</mat-card-title>
      <mat-card-content>
        Izaberite nepravilnost na kojoj želite da radite.
      </mat-card-content>
    </mat-card>

    <form #unApprovedForm="ngForm" (ngSubmit)="onUnApprovedSubmit()" *ngIf="selectedMode == 'viewAndEditIrregularity'">
      <mat-card class="moderate-irregularity-form">
        <mat-card-title>{{unApprovedInfo.title}}</mat-card-title>
        <mat-card-content>
          <div class="unaproved-irregularity">
            <p>
              <span>
                Opis nepravilnosti:
              </span>
              <span>
                {{unApprovedInfo.description}}
              </span>
            </p>
            <p>
              <span>
                Opština / Grad:
              </span>
              <span>
                {{unApprovedInfo.location.municipality}}
              </span>
            </p>
            <p>
              <span>
                Adresa:
              </span>
              <span>
                {{unApprovedInfo.location.address}}
              </span>
            </p>
            <p>
              <span>
                Glasačko mesto:
              </span>
              <span>
                {{unApprovedInfo.location.votingUnitNumber}}
              </span>
            </p>
            <p>
              <span>
                Koordinate:
              </span>
              <span>
                {{unApprovedInfo.location.geoLocation.lat}} , {{unApprovedInfo.location.geoLocation.lng}}
              </span>
            </p>
            <p>
              <span>
                Datum:
              </span>
              <span>
                {{unApprovedInfo.reportedAt | date}}
              </span>
            </p>
            <p>
              <span>
                Link / izvor:
              </span>
              <a href="{{unApprovedInfo.link}}" target="_blank">
                {{unApprovedInfo.link}}
              </a>
            </p>
            <p>
              <span>
                Ime kontakt osobe:
              </span>
              <span>
                {{unApprovedInfo.contact.name}}
              </span>
            </p>
            <p>
              <span>
                Email kontakt osobe:
              </span>
              <span>
                {{unApprovedInfo.contact.email}}
              </span>
            </p>
            <p>
              <span>
                Telefon kontakt osobe:
              </span>
              <span>
                {{unApprovedInfo.contact.phoneNumber}}
              </span>
            </p>

            <div class="moderate-detail__attatchments" *ngIf="unApprovedInfo.attachments && unApprovedInfo.attachments.length > 0">
              <p><span>Priloženo:</span></p>
              <div class="attatchment">
                <span *ngFor="let attatchment of unApprovedInfo.attachments; let a = index">
                  <a href="{{attatchment}}" target="_blank">{{attatchment}}</a>
                </span>
              </div>
            </div>

          </div>
        </mat-card-content>
    </mat-card>
  </form>

  </div>
</div>
