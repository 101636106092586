<div class="report-form-wrapper">

  <mat-card class="report-form">
    <form #reportForm="ngForm" (ngSubmit)="onReportSubmit()">
    <mat-card-title>Prijavi nepravilnost</mat-card-title>
    <mat-card-content>

      <p class="report-form__disclaimer">Osim preko web aplikacije i <a href="https://play.google.com/store/apps/details?id=com.lule.ferislobodniizbori&hl=en" target="_blank">android aplikacije</a>, nepravilnosti možete prijaviti i preko mejla: <a href="mailto:fisi.nepravilnost@gmail.com">fisi.nepravilnost@gmail.com</a>.
      Potrebni su opis nepravilnosti, lokacija gde se nepravilnost dogodila, datum kada se dogodila i bilo kakav dokaz da je do nepravilnosti došlo.</p>

      <div class="form-fields-wrapper">
        <div class="form-field-100">
          <mat-form-field appearance="fill">
            <mat-label>Nepravilnost</mat-label>
            <input matInput type='text' [(ngModel)]="reportInfo.title" name="title" required>
          </mat-form-field>
        </div>
        <div class="form-field-100">
          <mat-form-field appearance="fill" class="example-full-width">
            <mat-label>Opišite nepravilnost</mat-label>
            <textarea matInput rows= "6" [(ngModel)]="reportInfo.description" name="description" required></textarea>
          </mat-form-field>
        </div>
      </div>

      <p>Kako bismo izbornu nepravilnost prikazali na mapi, potrebna nam je lokacija na kojoj se izborna nepravilnost dogodila.</p>

      <div class="form-fields-wrapper">
        <div class="form-field-50">
          <mat-form-field appearance="fill">
            <mat-label>Opština</mat-label>
            <input matInput type='text' [(ngModel)]="reportInfo.location.municipality" name="municipality" required>
          </mat-form-field>
        </div>
        <div class="form-field-50">
          <mat-form-field appearance="fill">
            <mat-label>Adresa</mat-label>
            <input matInput type='text' [(ngModel)]="reportInfo.location.address" name="address" required>
          </mat-form-field>
        </div>
      </div>

      <p>Izaberite datum kada se izborna nepravilnost dogodila.</p>

      <div class="form-fields-wrapper">
        <div class="form-field-100">
          <mat-form-field appearance="fill">
            <mat-label>Datum nepravilnosti</mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="reportInfo.reportedAt" name="reportedAt">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <p>Sledeća polja nisu obavezna. Dajte nam svoj kontakt kako bismo potvrdili informacije o nepravilnosti ukoliko za to postoji potreba.</p>

      <div class="form-fields-wrapper">
        <div class="form-field-100">
          <mat-form-field appearance="fill">
            <mat-label>Ime i prezime</mat-label>
            <input matInput type='text' [(ngModel)]="reportInfo.contact.name" name="name">
          </mat-form-field>
        </div>
        <div class="form-field-50">
          <mat-form-field appearance="fill">
            <mat-label>E-mail</mat-label>
            <input matInput type='text' [(ngModel)]="reportInfo.contact.email" name="email">
          </mat-form-field>
        </div>
        <div class="form-field-50">
          <mat-form-field appearance="fill">
            <mat-label>Telefon</mat-label>
            <input matInput type='text' [(ngModel)]="reportInfo.contact.phoneNumber" name="phoneNumber">
          </mat-form-field>
        </div>
      </div>

      <p>Priložite fotografiju, video ili bilo koj drugi dokument koji dokazuje izbornu nepravilnost.</p>

      <div class="file-upload">
        <input (change)="fileChange($event)"
          type="file"
          class="custom-file-input file-upload__input"
          multiple="true"
          accept="image/*,audio/*,video/*,.pdf"
          id="fileUploadSomething"
          aria-describedby="inputGroupFileAddon01" />
          <label for="fileUploadSomething" class="file-upload__label">Priložite slike, video ili dokumenta</label>
      </div>

      <span *ngIf="filesLength == 1" class="files-selected">{{filesLength}} fajl izabran.</span>
      <span *ngIf="filesLength > 1 && filesLength < 5" class="files-selected">{{filesLength}} fajla izabrana.</span>
      <span *ngIf="filesLength > 4" class="files-selected">{{filesLength}} fajlova izabrano.</span>

      <!-- if file too big -->

      <div class="warning file-size" *ngIf="showFileSizeWarning">
        <mat-icon>warning</mat-icon>
        <mat-icon class="close-icon" (click)="closeWarnings()">close</mat-icon>
        <div class="warning-body">
          <p> Podigli ste preveliki fajl da bismo ga sačuvali na našem serveru.</p>
          <p> Ili ga smanjite, ili podignite ga na servis za hosting fajlova kao što su:</p>
          <a href="https://wetransfer.com/" target="_blank">Wetransfer</a>
          <a href="https://www.google.com/drive/" target="_blank">Google Drive</a>
          <a href="https://www.dropbox.com/" target="_blank">Dropbox</a>
          <p>Nakon podizanja, pošaljite nam link ka fajlovima u opisu nepravilnosti. </p>
        </div>
      </div>

      <div class="warning file-count" *ngIf="showFileCountWarning">
        <mat-icon>warning</mat-icon>
        <mat-icon class="close-icon" (click)="closeWarnings()">close</mat-icon>
        <div class="warning-body">
          <p> Maksimalni broj fajlova je 10.</p>
        </div>
      </div>

    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="primary" [disabled]="!reportForm.valid">Pošalji</button>
    </mat-card-actions>
  </form>
</mat-card>


</div>

