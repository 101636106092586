import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VERSION, MatDialog, MatDialogRef } from '@angular/material';
import { StatusChangeDialogComponent } from './modals/status-change-dialog/status-change-dialog.component';
import { DataService } from 'src/app/services/data.service';

@Component({
  templateUrl: './moderateApproved.component.html',
  styleUrls: ['./moderateApproved.component.scss']
})
export class ModerateApprovedComponent implements OnInit {

  statusChangeDialogRef: MatDialogRef<StatusChangeDialogComponent>;
  searchTerm = '';
  filters: any = {};

  today = new Date();
  newStatus;
  // editMode = false;

  public newInfo = {
    description: '',
    title: '',
    location: {
      geoLocation: {
        lat: null,
        lng: null
      },
     municipality: '',
     address: '',
     votingUnitNumber: ''
    },
    reportedAt: this.today,
    attachmentUrl: '',
    contact: {
      email: '',
      name: '',
      phoneNumber: ''
    },
    attachments: '',
    link: '',
    imageUrl: ''
  };

  public approvedInfo = {
    description: '',
    title: '',
    location: {
      geoLocation: {
        lat: null,
        lng: null
      },
     municipality: '',
     address: '',
     votingUnitNumber: ''
    },
    reportedAt: this.today,
    attachment: [],
    attachmentUrl: '',
    attachments: '',
    contact: {
      email: '',
      name: '',
      phoneNumber: ''
    },
    link: '',
    imageUrl: '',
    newstatus: ''
  };

  approvedIrregularities: any = [];
  selectedIndex: number = null;
  selectedId: any = null;
  selectedMode: any = null;
  newIrregularity: any = {};
  irregularityOpen = false;

  // today = new Date();
  filesLength = 0;
  max_files = 10;
  uploadedFiles: Array<any> = new Array<any>();
  showFileSizeWarning = false;
  showFileCountWarning = false;

  public reportInfo = {
    description: '',
    title: '',
    location: {
      geoLocation: {
        lat: null,
        lng: null
      },
     municipality: '',
     address: '',
     votingUnitNumber: ''
    },
    reportedAt: this.today,
    attachmentUrl: '',
    contact: {
      email: '',
      name: '',
      phoneNumber: ''
    },
    attachments: '',
    link: '',
    imageUrl: ''
  };

  constructor(private snackBar: MatSnackBar,
              private dialog: MatDialog,
              private dataservice: DataService) { }

  ngOnInit() {
    this.getIrregularities();
  }

  getIrregularities() {
    this.dataservice.getAdminReports({draft: true}).subscribe((resp) => {
      this.approvedIrregularities = resp;
      console.log(resp);
     });

    // this.dataservice.getReports().subscribe((resp) => {
    //   this.approvedIrregularities = resp;
    //   console.log(resp);
    //  });
  }

  selectIrregularity(id) {
    this.selectedId = id;
    this.approvedInfo = this.approvedIrregularities.find(x => x.id === this.selectedId);
    this.selectedMode = 'viewAndEditIrregularity';
  }

  onApprovedSubmit() {
    console.log(this.approvedInfo);
    this.openSnackBar('Nepravilnost je sačuvana.', '✕');
  }

  createNewIrregularity() {
    this.selectedMode = 'createNewIrregularity';
  }

  onNewIrregularitySubmit() {
    console.log(this.newInfo);
    const formData = this.getFilesFormData();
    this.dataservice.postReport(this.newInfo, formData).subscribe((resp) => {
      console.log(resp);
      // this.selectedID = resp.reportId;
      // this.serverMessage = resp.message;
      this.selectedMode = '';
      this.getIrregularities();
      this.openSnackBar('Nepravilnost je sačuvana.', '✕');
      this.filesLength = 0;
      this.newInfo = {
        description: '',
        title: '',
        location: {
          geoLocation: {
            lat: '',
            lng: ''
          },
         municipality: '',
         address: '',
         votingUnitNumber: ''
        },
        reportedAt: this.today,
        attachmentUrl: '',
        contact: {
          email: '',
          name: '',
          phoneNumber: ''
        },
        attachments: '',
        link: '',
        imageUrl: ''
      };
     });
  }

  setIrregularityStatus(status) {
    this.approvedInfo.newstatus = status;
    this.openStatusChangeDialog();
    // approved draft unapproved
  }

  openStatusChangeDialog() {
    this.statusChangeDialogRef = this.dialog.open(StatusChangeDialogComponent, {
      data: this.approvedInfo
    });
    this.statusChangeDialogRef.afterClosed().subscribe(
      data => this.getIrregularities()
    );
  }

  filterByTitle(searchTerm) {
    this.searchTerm = searchTerm;
    this.selectedMode = null;
    this.filters = {
      title: {
        $or: [this.searchTerm]
      }
    };
  }

  closeWarnings() {
    this.showFileSizeWarning = false;
    this.showFileCountWarning = false;
  }

  fileChange(element) {
    console.log(element.target.files);
    this.filesLength = element.target.files.length;
    this.closeWarnings();
    if (!this.filesLength) {
      console.log('no files selected', element.target);
      return;
    }

    if (this.filesLength > this.max_files) {
      console.log('cannot select more than ' + this.max_files + ' files');
      this.filesLength = this.max_files;
      this.showFileCountWarning = true;
      element.target.value = '';
      this.filesLength = element.target.files.length;
    }

    // this.uploadedFiles = element.target.files.slice(0,filesLength);
    for (let i = 0; i < this.filesLength; i++) {
      const file = element.target.files[i];
      this.uploadedFiles[i] = file;
      if (file.size > 52428800) {
        this.showFileSizeWarning = true;
        element.target.value = '';
        this.filesLength = element.target.files.length;
        console.log(element.target.files);
      }

    }
    // this.filesMap.set(element.target.id, element.target.files[0]);
  }

  getFilesFormData() {
    let formData = new FormData();
    console.log('formdata', formData.append);
    const maxFiles = 10;
    let countFiles = 0;
    this.uploadedFiles.forEach((item, index) => {
      formData.append("files[]", item, item.name);
      countFiles++;
    });
    // this.filesMap.forEach((item: any, key: String) => {
    //   if(key && countFiles < maxFiles) {
    //     formData.append("files[]", item, item.name);
    //     // console.log('formdata ' + countFiles, formData);
    //     countFiles++;
    //   }
    // })
    if (countFiles === 0) {
      return null;
    }
    return formData;
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'success'
    });
  }

}
