import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public contactInfo = {
    name: '',
    email: '',
    message: ''
  };

  constructor(private dataservice: DataService,
              private snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  onContactSubmit() {
    console.log(this.contactInfo);

    this.dataservice.postContact(this.contactInfo).subscribe((resp) => {
      console.log(resp);
      this.openSnackBar('Uspešno ste poslali poruku.', '✕');
      this.contactInfo = {
        name: '',
        email: '',
        message: ''
      };
     });

  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'success'
    });
  }

}
