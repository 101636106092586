
      <mat-sidenav-container>
          <mat-sidenav #sidenav mode="side" class="irregularity">
            <img [src]="selectedIrregularity.imageUrl" class="irregularity__featured-image"/>
            <img *ngIf="!selectedIrregularity.imageUrl" src="assets/images/thumb.jpg" class="irregularity__featured-image"/>
            <div class="irregularity__content">
              <h1>{{selectedIrregularity.title}}</h1>
              <h4>
                <i class="material-icons">location_on</i> <span>{{selectedIrregularity.location.municipality}}, {{selectedIrregularity.location.address}}</span>
              </h4>
              <p><span>Opis:</span> {{selectedIrregularity.description}}</p>
              <p *ngIf="selectedIrregularity.link"><span>Izvor/link:</span> <a href="{{selectedIrregularity.link}}" target="_blank">{{selectedIrregularity.link}}</a></p>
              <p *ngIf="selectedIrregularity.reportedAt"><span>Datum:</span> {{selectedIrregularity.reportedAt | date}}</p>
              <p *ngIf="selectedIrregularity.attachments"><span>Priloženo:</span></p>
              <div *ngIf="selectedIrregularity.attachments">
                <span *ngFor="let attatchment of selectedIrregularity.attachments; let a = index">
                  <a href="{{attatchment}}" target="_blank">{{attatchment}}</a>
                </span>
              </div>
            </div>

            <button mat-fab color="primary" (click)="resetMap()">
              <mat-icon>
                keyboard_backspace
              </mat-icon>
            </button>

          </mat-sidenav>
      <mat-sidenav-content>

      <div *ngIf="showNoIrregularitiesNotice" class="no-irregularities-wrapper">
        <div class="no-irregularities-notice">
          <mat-icon>info</mat-icon>
          <span>Trenutno nema ni jedne prijavljene nepravilnosti za ovu godinu.</span>
        </div>
      </div>

      <agm-map
      [latitude]="lat"
      [longitude]="lng"
      [zoom]="zoom"
      [disableDefaultUI]="true"
      [zoomControl]="false"
      [styles]="styles"
      (mapClick)="mapClicked($event)">

      <agm-marker-cluster imagePath="https://fisi.rs/assets/images/m">
      <ng-container *ngFor="let irregularity of approvedIrregularities; let i = index">

          <agm-marker
            (markerClick)="clickedMarker(irregularity.label, i)"
            [latitude]="toNumber(irregularity.location.geoLocation.lat)"
            [longitude]="toNumber(irregularity.location.geoLocation.lng)"
            [label]="irregularity.label"
            [markerDraggable]="irregularity.draggable"
            (dragEnd)="markerDragEnd(irregularity, $event)"
            [iconUrl]="'assets/images/nepravilnostMarker.png'">
            <!-- <agm-info-window>
              <strong>InfoWindow content</strong>
            </agm-info-window> -->
          </agm-marker>

    </ng-container>
  </agm-marker-cluster>

      <!-- <agm-circle [latitude]="lat + 0.3" [longitude]="lng"
          [radius]="5000"
          [fillColor]="'red'"
          [circleDraggable]="true"
          [editable]="true">
      </agm-circle> -->

    </agm-map>


    </mat-sidenav-content>
  </mat-sidenav-container>
