import { Injectable, EventEmitter, Output } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject, Observable, of } from 'rxjs';
import { first } from 'rxjs/operators';
import { User } from 'firebase';

const LOCAL_STORAGE_TOKEN = 'fifbt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  loggedInChange: Subject<boolean> = new Subject<boolean>();

  private _subject = new Subject<any>();

  public loggedIn: boolean;

  constructor(public fb: AngularFireAuth,
              private snackBar: MatSnackBar) {

    // this.loggedIn = localStorage.getItem(LOCAL_STORAGE_TOKEN) ? true : false;
    if (localStorage.getItem(LOCAL_STORAGE_TOKEN) === 'null') {
      this.loggedIn = false;
    } else if (!LOCAL_STORAGE_TOKEN) {
      this.loggedIn = false;
    } else {
      this.loggedIn = true;
    }
    this.initFirebaseListener();
  }

  private initFirebaseListener() {
    // this.loggedInChange.subscribe((resp) => this.loggedIn = resp);

    this.fb.auth.onAuthStateChanged(async (user: User) => {
      if (user) {
        const idToken = await user.getIdToken(/* forceRefresh */ true);
        console.log('LOGGED IN', idToken);
        localStorage.setItem(LOCAL_STORAGE_TOKEN, idToken);
        this.loggedIn = true;
        this.loggedInChange.next(true);
      } else {
        localStorage.setItem(LOCAL_STORAGE_TOKEN, null);
        this.loggedIn = false;

        this.loggedInChange.next(false);
        console.log('nije ulogovan');
      }
    });
  }

  getAdminToken() {
    this.fb.auth.currentUser.getIdToken(/* forceRefresh */ true)
    .then(idToken => {
      return idToken;
    })
    .catch(err => {
      console.error('error getting fb token', err);
      throw Error('Error getting fb token');
    });

    return localStorage.getItem(LOCAL_STORAGE_TOKEN) || '';
  }

  login(email, password) {
    this.fb.auth.signInWithEmailAndPassword(email, password).then(
      data => {
        const user = data.user;
        this.loggedIn = true;
      })
      .catch(err => {
        this.openSnackBar('Došlo je do greške.', '✕');
        console.error('error logging in with Firebase', err);
      });
  }

  logout() {
    this.fb.auth.signOut();
    console.log('logout - this.loggedIn= ' + this.loggedIn);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'error'
    });
  }

}
