<div class="static-page-wrapper">

<mat-card class="static-page">
  <mat-card-title>O aplikaciji</mat-card-title>
  <mat-card-content>

      <h3>Čemu služi ova platforma?</h3>

      <p>30 godina izbori u Srbiji se kradu, biračima se manipuliše, zastrašuju se i podmićuju, dokazi se ignorišu i institucije ne reaguju. Ovom platformom stajemo tome na put.</p>
      <p>Kako? Tako što od sada svaki građanin može da bude kontrolor izbornog procesa.</p>
      <p>Možeš da prijaviš svaku nepravilnost koju uočiš od dana raspisivanja izbora – tokom kampanje, na sam izborni dan i po proglašenju izbornih rezultata. </p>
      <p>Putem aplikacije, svaka prijavljena nepravilnost biće prikazana na interaktivnoj mapi, te će i celokupna javnost imati pregled svih mesta na kojima su zabeležena narušavanja izbornog procesa. </p>
      <p>Prikupljeni podaci o nepravilnostima biće procesuirani i podneti nadležnim organima, ali i svim organizacijama koje se bave praćenjem izbora.</p>
      <p>Po završetku izbornog procesa, imaćemo potpunu sliku o izborima. </p>

      <p>Videće je svi i više niko neće moći da je ignoriše.</p>
      <p>Ako su već institucije zakazale, mi – građani – ne smemo. Uključi se i čuvaj izbore.</p>

      <h3>Prijavite nepravilnost</h3>
      <p>Izborne nepravilnosti jesu povrede zakona kojima se reguliše izborni proces, a koje za cilj imaju manipulisanje biračima, krađu glasova i prekrajanje volje građana. </p>
      <p>Beležimo sve: šta se dešava tokom kampanje pre izbornog dana, zatim na sam dan glasanja, i u periodu nakon održavanja izbora.</p>
      <p>Pošalji nam opis, fotografiju, video, audio snimak ili bilo kakav drugi materijal kojim možemo da dokumentujemo sledeće nepravilnosti:</p>

      <ul>
        <li>pritisci i zastrašivanje birača</li>
        <li>kupovina glasova (novcem, ali i brašnom, uljem, namirnicama)</li>
        <li>zloupotreba javnih resursa u predizborne svrhe – „funkcionerska kampanja“</li>
        <li>zloupotreba ličnih podataka birača</li>
        <li>neravnopravan medijski tretman učesnika izbora </li>
        <li>predizborni plakati na mestima na kojima je zabranjeno njihovo isticanje</li>
        <li>neuredan birački spisak (podaci preminulih lica na spiskovima)</li>
        <li>kršenje predizborne tišine</li>
        <li>fotografisanje glasačkih listića, </li>
        <li>glasanje bez utvrđivanja identiteta,  </li>
        <li>višestruko glasanje  </li>
        <li>glasanje u ime osoba koje nisu izašle na glasanje </li>
        <li>nasilje na biračkom mestu</li>
        <li>pogrešno brojanje glasova</li>
        <li>nepostupanje po prigovorima na izborni proces...</li>
      </ul>

      <h3>Kontakt</h3>
      <p>Ukoliko se nepravilnost još nije dogodila, ali imaš saznanja da se sprema – javi nam. Reagovaćemo pravovremeno i pokušati da sprečimo narušavanje izbornog procesa.</p>


  </mat-card-content>
</mat-card>


</div>
