<div class="contact-form-wrapper">

  <form #contactForm="ngForm" (ngSubmit)="onContactSubmit()">

    <mat-card class="contact-form">
        <mat-card-title>Kontakt</mat-card-title>
        <mat-card-content>

          <div class="contact-form-notice">
            <p>Ukoliko se nepravilnost još nije dogodila, ali imaš saznanja da se sprema – javi nam.</p>
            <p>Reagovaćemo pravovremeno i pokušati da sprečimo narušavanje izbornog procesa.</p>
          </div>

          <div class="form-fields-wrapper">
            <div class="form-field-100">
              <mat-form-field appearance="fill">
                <mat-label>Ime i prezime</mat-label>
                <input matInput type='text' [(ngModel)]="contactInfo.name" name="name" required>
              </mat-form-field>
            </div>
            <div class="form-field-100">
              <mat-form-field appearance="fill">
                <mat-label>Email</mat-label>
                <input matInput type='text' [(ngModel)]="contactInfo.email" name="email" required>
              </mat-form-field>
            </div>
            <div class="form-field-100">
              <mat-form-field appearance="fill" class="example-full-width">
                <mat-label>Vaša poruka</mat-label>
                <textarea matInput rows= "6" [(ngModel)]="contactInfo.message" name="message" required></textarea>
              </mat-form-field>
            </div>
          </div>

        </mat-card-content>
        <mat-card-actions>
          <button mat-raised-button color="primary" [disabled]="!contactForm.valid">Pošalji</button>
        </mat-card-actions>
    </mat-card>

</form>

</div>
