import { Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataService } from 'src/app/services/data.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-status-change-dialog',
  templateUrl: './status-change-dialog.component.html',
  styleUrls: ['./status-change-dialog.component.scss']
})
export class UnapprovedStatusChangeDialogComponent implements OnInit {

  newstatus;

  constructor(public dialogRef: MatDialogRef<UnapprovedStatusChangeDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dataservice: DataService,
              private snackBar: MatSnackBar) { }

  ngOnInit() {
    console.log(this.data);
  }

  statusChange() {
    this.dataservice.updateReport(this.data.id, {confirmed: false, draft: true}).subscribe((resp) => {
      console.log(resp);
      this.openSnackBar('Uspešno ste promenili status.', '✕');
     });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'success'
    });
  }

}
