import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Injectable()
export class DataService {

  @BlockUI() blockUI: NgBlockUI;
  baseUrl = 'https://ferislobodniizbori.appspot.com/api/';
  // baseUrl = 'http://localhost:8080/api/';

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private auth: AuthService) {

  }

  getReports(electionYear): Observable<any> {
    this.blockUI.start(); // Start blocking
    return this.http.get(this.baseUrl + 'reports?year=' + electionYear)
    .pipe(map((data: any) => {
      this.blockUI.stop(); // Stop blocking
      return data;
    }, (error: any) => {
      console.log('Greška pri pozivanju nepravilnosti', error);
      this.openSnackBar('Došlo je do greške', '✕');
      return null;
    }));
  }

  postReport(params: object, files: FormData): Observable<any> {
    // const params = {};
    console.log('files', files);
    this.blockUI.start(); // Start blocking
    return this.http.post(this.baseUrl + 'reports', params)
    .pipe(map((data: any) => {
      console.log('data received', data);
      this.blockUI.stop(); // Stop blocking
      if (files) {
        return this.postAttatchment(files, data.reportId);
      } else {
        return data;
      }
    }, (error: any) => {
      console.log('Greška pri čuvanju nepravilnosti', error);
      this.openSnackBar('Došlo je do greške', '✕');
      return null;
    }));
  }

  postAttatchment(params, id) {
    if (!id) {
      this.openSnackBar('ID nepravilnosti nije prisutan', '✕');
      throw new Error('ID nepravilnosti nije prisutan');
    }
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    return new Promise((resolve) => {
      this.http.post(this.baseUrl + 'reports/' + id + '/attachment', params, { headers: headers })
      .subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  postContact(params): Observable<any> {
    console.log(params);
    // const params = {};
    this.blockUI.start(); // Start blocking
    return this.http.post(this.baseUrl + 'contact', params)
    .pipe(map((data: any) => {
      this.blockUI.stop(); // Stop blocking
      return data;
    }, (error: any) => {
      console.log('Greška pri slanju kontakt poruke', error);
      this.openSnackBar('Došlo je do greške', '✕');
      return null;
    }));
  }

  /**
   * @rile ovaj api zoves za sve reports
   *
   * @param params {confirmed, draft, isOriginal}
   */
  getAdminReports(params): Observable<any> {
    const uidToken = this.auth.getAdminToken();
    const headers = new HttpHeaders().set('Authorization', uidToken);

    // headers.append('Authorization', uidToken); ovo ladno ne radi iz nekog jebenog razloga postoji issue, nisam provjerio sta je

    this.blockUI.start(); // Start blocking
    return this.http.get(this.baseUrl + 'reports/admin', {headers: headers, params: params})
      .pipe(map((data: any) => {
        this.blockUI.stop(); // Stop blocking
        return data;
      }, (error: any) => {
        console.error('Greška pri pri pozivanju admin nepravilnosti', error);
        this.openSnackBar('Došlo je do greške', '✕');
        return null;
      }));
  }

  updateReport(id, params): Observable<any> {
    const uidToken = this.auth.getAdminToken();
    const headers = new HttpHeaders().set('Authorization', uidToken);
    if (!id) {
      this.openSnackBar('ID nepravilnosti nije prisutan', '✕');
      throw new Error('ID nepravilnosti nije prisutan');
    }
    this.blockUI.start(); // Start blocking
    return this.http.put(this.baseUrl + 'reports/' + id, params, {headers: headers})
    .pipe(map((data: any) => {
      this.blockUI.stop(); // Stop blocking
      return data;
    }, (error: any) => {
      console.log('Greška pri čuvanju nepravilnosti', error);
      this.openSnackBar('Došlo je do greške', '✕');
      return null;
    }));
  }

  deleteReport(id): Observable<any> {
    const uidToken = this.auth.getAdminToken();
    const headers = new HttpHeaders().set('Authorization', uidToken);
    if (!id) {
      this.openSnackBar('ID nepravilnosti nije prisutan', '✕');
      throw new Error('ID nepravilnosti nije prisutan');
    }
    this.blockUI.start(); // Start blocking
    return this.http.delete(this.baseUrl + 'reports/' + id, {headers: headers})
    .pipe(map((data: any) => {
      this.blockUI.stop(); // Stop blocking
      return data;
    }, (error: any) => {
      console.log('Greška pri čuvanju nepravilnosti', error);
      this.openSnackBar('Došlo je do greške', '✕');
      return null;
    }));
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'error'
    });
  }


  private getAdminReportsParams(params): string {
    if (!params || !params.keys || params.keys.length === 0) {
      return '';
    }
    let encodedParams = '?';
    for (let key in params) {
      if (encodedParams !== '?') {
        encodedParams += '&';
      }
      encodedParams += key + '=' + encodeURIComponent(params[key]);
    }
    return encodedParams;
  }
}



