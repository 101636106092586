<div class="static-page-wrapper">

  <mat-card class="static-page">
    <mat-card-title>Izborni zakoni Republike Srbije</mat-card-title>
    <mat-card-content>

      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Zakon o lokalnim izborima
            </mat-panel-title>
          </mat-expansion-panel-header>
          
            <h2>I OSNOVNE ODREDBE</h2>
            <h3>Član 1</h3>
            <p>Ovim zakonom uređuju se izbor i prestanak mandata odbornika skupština jedinica lokalne samouprave (u daljem tekstu: odbornici).</p>
            <h3>Član 2</h3>
            <p>Odbornici se biraju na četiri godine.</p>
            <p>Izbori za odbornike moraju se raspisati najkasnije 45 dana pre kraja mandata odbornika kojima ističe mandat.</p>
            <h3>Član 3</h3>
            <p>Građani biraju odbornike na osnovu slobodnog, opšteg i jednakog izbornog prava. Izborno pravo građani ostvaruju neposredno, tajnim glasanjem.</p>
            <p>Niko nema pravo da, po bilo kom osnovu, sprečava ili primorava građanina da glasa, da ga poziva na odgovornost zbog glasanja ili da od njega traži da se izjasni za koga je glasao ili zašto nije glasao.</p>
            <h3>Član 4</h3>
            <p>Građani imaju pravo da preko sredstava javnog informisanja budu ravnopravno, blagovremeno, istinito, nepristrasno i potpuno obavešteni o izbornoj kampanji podnosilaca izbornih lista i kandidatima s izbornih lista, kao i o drugim događajima značajnim za izbore.</p>
            <p>Sredstva javnog informisanja dužna su da u izbornoj kampanji obezbede ravnopravnost, blagovremenost, istinitost, nepristrasnost i potpunost u obaveštavanju o svim podnosiocima izbornih lista i kandidatima s tih lista, kao i o drugim događajima značajnim za izbore.</p>
            <p>Izborna kampanja, u smislu ovog zakona, predstavlja sve javne, političke, promotivne i druge aktivnosti podnosilaca izbornih lista i kandidata s tih lista.</p>
            <h3>Član 5</h3>
            <p>Odbornici se biraju na osnovu lista političkih stranaka, njihovih koalicija, i lista koje predlože grupe građana (u daljem tekstu: izborna lista).</p>
            <h3>Član 6</h3>
            <p>Pravo da bira odbornika ima punoletan, poslovno sposoban državljanin Republike Srbije koji ima prebivalište na teritoriji jedinice lokalne samouprave u kojoj ostvaruje izborno pravo.</p>
            <p>Za odbornika, može biti izabran punoletan, poslovno sposoban državljanin Republike Srbije koji ima prebivalište na teritoriji jedinice lokalne samouprave u kojoj je predložen za odbornika.</p>
            <h3>Član 7</h3>
            <p>Izbor odbornika obavlja se u opštini kao jedinstvenoj izbornoj jedinici.</p>
            <p>Odbornički mandati raspodeljuju se između izbornih lista srazmerno broju dobijenih glasova koji je osvojila svaka od izbornih lista.</p>
            <h3>Član 8</h3>
            <p>Izbore za odbornike raspisuje predsednik Narodne skupštine.</p>
            <p>Od dana raspisivanja izbora do dana održavanja izbora ne može proteći manje od 45, ni više od 60 dana.</p>
            <p>Odlukom o raspisivanju izbora određuje se dan održavanja izbora, kao i dan od kada počinju teći rokovi za vršenje izbornih radnji.</p>
            <p>Kada se istovremeno održavaju izbori za odbornike i izbori za narodne poslanike Narodne skupštine, poslove biračkih odbora na sprovođenju izbora za odbornike obavljaju birački odbori obrazovani za sprovođenje izbora za narodne poslanike.</p>
            <p>U nacionalno mešovitim jedinicama lokalne samouprave, u smislu zakona kojim se uređuje lokalna samouprava, prilikom imenovanja biračkih odbora iz stava 4. ovog člana, posebno će se voditi računa o zastupljenosti političkih stranaka nacionalnih manjina u skupštini jedinice lokalne samouprave.</p>
            <p>U slučaju iz stava 4. ovog člana, podnosilac izborne liste za odbornike, koji nije podneo izbornu listu za narodne poslanike, ima pravo na predstavnika u biračkom odboru u proširenom sastavu pod uslovima propisanim ovim zakonom.</p>
            <p>Odluka o raspisivanju izbora objavljuje se u "Službenom glasniku Republike Srbije".</p>
            <h3>Član 9</h3>
            <p>U jedinicama lokalne samouprave u kojima živi stanovništvo mešovitog nacionalnog sastava, omogućuje se srazmerna zastupljenost nacionalnih manjina u skupštinama jedinica lokalne samouprave, u skladu s Ustavom i ovim zakonom.</p>
            <h3>Član 10</h3>
            <p>Izbore za odbornike sprovode izborne komisije jedinica lokalne samouprave i birački odbori.</p>
            <h2>II IZBOR ODBORNIKA SKUPŠTINE JEDINICE LOKALNE SAMOUPRAVE</h2>
            <h3>Organi za sprovođenje izbora</h3>
            <h3>Član 11</h3>
            <p>Organi za sprovođenje izbora su izborna komisija jedinice lokalne samouprave (u daljem tekstu: izborna komisija) i birački odbori.</p>
            <p>Organi za sprovođenje izbora su samostalni i nezavisni u radu i rade na osnovu zakona i propisa donetih na osnovu zakona.</p>
            <p>Za svoj rad organi za sprovođenje izbora odgovaraju organu koji ih je imenovao.</p>
            <p>Svi organi i organizacije dužni su da pružaju pomoć organima za sprovođenje izbora i da dostavljaju podatke koji su im potrebni za rad.</p>
            <h3>Član 12</h3>
            <p>Članovi organa za sprovođenje izbora i njihovi zamenici mogu biti samo građani koji imaju izborno pravo, kao i prebivalište na teritoriji jedinice lokalne samouprave.</p>
            <p>Članovima organa za sprovođenje izbora i njihovim zamenicima prestaje funkcija u ovim organima kad prihvate kandidaturu za odbornika.</p>
            <h3>Član 13</h3>
            <p>Izborna komisija radi u stalnom sastavu (imenovani članovi) i u proširenom sastavu (opunomoćeni članovi).</p>
            <p>Izborna komisija radi u proširenom sastavu od dana utvrđivanja tog sastava do završetka izbora.</p>
            <p>Nijedna politička stranka ili stranačka koalicija ne može imati više od polovine članova u stalnom sastavu organa za sprovođenje izbora.</p>
            <p>U rešenju o imenovanju predsednika i članova organa za sprovođenje izbora, pored ličnog imena predsednika, odnosno člana, mora biti navedena njegova politička pripadnost ili naziv stranke, odnosno stranačke koalicije na čiji predlog je imenovan.</p>
            <h3>Član 14</h3>
            <p>Izbornu komisiju u stalnom sastavu čine predsednik i najmanje šest članova koje imenuje skupština jedinice lokalne samouprave, na predlog odborničkih grupa u skupštini jedinice lokalne samouprave, srazmerno broju odbornika a u proširenom sastavu - i po jedan opunomoćeni predstavnik podnosilaca izborne liste koji je predložio najmanje dve trećine kandidata od ukupnog broja odbornika koji se bira.</p>
            <p>Političke stranke i koalicije političkih stranaka nacionalnih manjina koje predlože najmanje jednu trećinu kandidata za odbornike od ukupnog broja odbornika koji se bira imaju pravo na svog predstavnika u proširenom sastavu izborne komisije.</p>
            <p>Izborna komisija ima sekretara koga imenuje skupština jedinice lokalne samouprave. Sekretar učestvuje u radu komisije bez prava odlučivanja.</p>
            <p>Predsednik, članovi izborne komisije u stalnom i proširenom sastavu i njen sekretar imaju zamenike.</p>
            <p>Za predsednika, zamenika predsednika, sekretara i zamenika sekretara izborne komisije imenuje se lice koje je diplomirani pravnik.</p>
            <p>Izborna komisija danom proglašenja izborne liste istovremeno rešenjem utvrđuje koji podnosilac izborne liste ispunjava uslove za određivanje svojih predstavnika u prošireni sastav ovog organa.</p>
            <p>Rešenje o ispunjenju ili neispunjenju uslova za određivanje opunomoćenog predstavnika podnosilaca izborne liste u prošireni sastav izborna komisija dostavlja podnosiocu izborne liste u roku od 24 časa od donošenja tog rešenja.</p>
            <p>Podnosilac izborne liste određuje svog opunomoćenog predstavnika u izbornoj komisiji i o tome obaveštava izbornu komisiju.</p>
            <p>Izborna komisija utvrđuje prošireni sastav u roku od 24 časa od prijema obaveštenja o licima koja ulaze u prošireni sastav.</p>
            <p>Ako podnosilac izborne liste ne odredi opunomoćenog predstavnika u izbornoj komisiji najkasnije pet dana pre dana određenog za održavanje izbora, izborna komisija nastavlja da radi i da punovažno odlučuje bez predstavnika podnosioca izborne liste.</p>
            <p>Protiv rešenja skupštine jedinice lokalne samouprave o imenovanju predsednika i članova izborne komisije u stalnom sastavu dopuštena je žalba Upravnom sudu u roku od 24 časa od donošenja rešenja.</p>
            <p>Protiv rešenja izborne komisije iz stava 7. ovog člana obezbeđena je zaštita po postupku za zaštitu izbornog prava koji je utvrđen ovim zakonom.</p>
            <h3>Član 15</h3>
            <p>Izborna komisija:</p>
            <p>1) stara se o zakonitosti sprovođenja izbora odbornika;</p>
            <p>2) određuje biračka mesta, pri čemu naročito vodi računa o ravnomernoj raspoređenosti birača na biračkim mestima i o dostupnosti biračkog mesta biračima;</p>
            <p>3) određuje biračke odbore i imenuje njihove članove;</p>
            <p>4) daje uputstva biračkim odborima u pogledu sprovođenja postupka izbora odbornika;</p>
            <p>5) propisuje obrasce i organizuje tehničke pripreme za sprovođenje izbora za odbornike;</p>
            <p>6) utvrđuje da li su izborne liste sačinjene i podnete u skladu sa propisima o izboru odbornika;</p>
            <p>7) proglašava izborne liste;</p>
            <p>8) utvrđuje oblik i izgled glasačkih listića, broj glasačkih listića za biračka mesta i zapisnički ih predaje biračkim odborima;</p>
            <p>9) utvrđuje i objavljuje ukupne rezultate izbora odbornika;</p>
            <p>10) podnosi izveštaj skupštini jedinice lokalne samouprave o sprovedenim izborima za odbornike;</p>
            <p>11) dostavlja ministarstvu nadležnom za poslove lokalne samouprave i republičkom organu nadležnom za poslove statistike podatke o sprovođenju i rezultatima izbora za odbornike, neposredno po završetku izbora;</p>
            <p>12) obavlja i druge poslove određene propisima o izboru odbornika.</p>
            <p>U svom radu, izborna komisija shodno primenjuje uputstva i druge akte Republičke izborne komisije koji se odnose na sprovođenje izbora za narodne poslanike.</p>
            <h3>Član 16</h3>
            <p>Birački odbor radi u stalnom i proširenom sastavu.</p>
            <p>Birački odbor u stalnom sastavu čine predsednik i najmanje četiri člana.</p>
            <p>U pogledu utvrđivanja sastava biračkog odbora u proširenom sastavu, primenjuju se odredbe ovog zakona koje se odnose na izbornu komisiju u proširenom sastavu.</p>
            <p>Predsednik i članovi biračkog odbora imaju zamenike.</p>
            <p>Birački odbor imenuje se najkasnije deset dana pre dana određenog za održavanje izbora.</p>
            <p>Rešenje o imenovanju biračkih odbora dostavlja se svim podnosiocima potvrđenih izbornih lista, u roku od 48 časova od dana donošenja rešenja.</p>
            <h3>Član 17</h3>
            <p>Birački odbor neposredno sprovodi glasanje, obezbeđuje pravilnost i tajnost glasanja, utvrđuje rezultate glasanja na biračkom mestu i obavlja druge poslove određene zakonom.</p>
            <p>Birački odbor se stara o održavanju reda na biračkom mestu za vreme glasanja. Bliža pravila o radu biračkog odbora određuje izborna komisija.</p>
            <h3>Kandidovanje</h3>
            <h3>Član 18</h3>
            <p>Kandidate za odbornike mogu predlagati registrovane političke stranke, koalicije registrovanih političkih stranaka, kao i grupe građana čije izborne liste svojim potpisima podrži najmanje 30 birača po predlogu za svakog kandidata na izbornoj listi. Predlagač mora na izbornoj listi imati najmanje jednu trećinu kandidata od ukupnog broja odbornika koji se bira.</p>
            <p>U jedinicama lokalne samouprave koje imaju manje od 20.000 birača, izborne liste iz stava 1. ovog člana utvrđene su i kada ih svojim potpisom podrži najmanje 200 birača.</p>
            <p>U ime političke stranke ili grupe građana, predloge iz st. 1. i 2. ovog člana može podneti samo lice koje je politička stranka ili grupa građana ovlastila.</p>
            <p>U ime koalicije stranaka, predlog iz st. 1. i 2. ovog člana podnose najviše dva ovlašćena lica.</p>
            <h3>Član 19</h3>
            <p>Izborna lista dostavlja se izbornoj komisiji najkasnije 15 dana pre dana određenog za održavanje izbora.</p>
            <p>Uz izbornu listu izbornoj komisiji dostavlja se dokumentacija, i to:</p>
            <p>1) potvrda o izbornom pravu za svakog kandidata sa izborne liste, u kojoj je naznačeno ime i prezime, datum rođenja, zanimanje i jedinstveni matični broj kandidata;</p>
            <p>2) pismena izjava kandidata da prihvata kandidaturu;</p>
            <p>3) potvrda o prebivalištu kandidata;</p>
            <p>4) pismena saglasnost nosioca liste;</p>
            <p>5) ovlašćenje lica koja podnose izbornu listu;</p>
            <p>6) uverenje o državljanstvu;</p>
            <p>7) potpisi birača koji su podržali određenu izbornu listu.</p>
            <p>Na dokumentaciju iz stava 2. tač 1), 3) i 6) ovog člana ne plaća se taksa.</p>
            <h3>Član 20</h3>
            <p>Jedno lice može biti kandidat za odbornika samo na jednoj izbornoj listi.</p>
            <p>Podnosilac izborne liste određuje redosled kandidata na listi.</p>
            <p>Na izbornoj listi mora biti 40% manje zastupljenog pola. Među svakih pet kandidata po redosledu na listi (prvih pet mesta, drugih pet mesta i tako do kraja liste) mora biti najmanje po dva kandidata - pripadnika onog pola koji je manje zastupljen na listi.</p>
            <p>Ako izborna lista ne ispunjava uslove iz stava 3. ovog člana smatraće se da sadrži nedostatke za proglašenje izborne liste, a predlagač liste biće pozvan u skladu sa ovim zakonom, da otkloni nedostatke liste.</p>
            <p>Ako predlagač liste ne otkloni nedostatke iz stava 4. ovog člana, izborna komisija odbiće proglašenje izborne liste, u skladu sa ovim zakonom.</p>
            <p>Kad po donošenju rešenja o proglašenju izborne liste kandidat bude pravnosnažnom sudskom odlukom lišen poslovne sposobnosti, izgubi državljanstvo Republike Srbije, odustane od kandidature ili ako nastupi njegova smrt, podnosilac izborne liste gubi pravo da predloži novog kandidata.</p>
            <h3>Član 21</h3>
            <p>Podnosilac izborne liste može povući listu najdocnije do dana utvrđivanja zbirne izborne liste.</p>
            <p>Povlačenjem izborne liste prestaje funkcija predstavnika podnosioca liste u svim organima za sprovođenje izbora, kao i sva prava koja mu u tom svojstvu po odredbama ovog zakona pripadaju.</p>
            <h3>Član 22</h3>
            <p>Naziv izborne liste određuje se prema nazivu političke stranke koja podnosi listu, a u naziv se može uključiti ime i prezime lica koje politička stranka odredi kao nosioca izborne liste.</p>
            <p>Ako dve ili više političkih stranaka podnesu zajedničku izbornu listu, naziv izborne liste i nosilac izborne liste određuju se sporazumno.</p>
            <p>Uz naziv izborne liste grupe građana podnosilac određuje bližu oznaku te liste, a u naziv se može uključiti ime i prezime lica koje grupa građana odredi kao nosioca izborne liste.</p>
            <p>Lice određeno kao nosilac izborne liste može biti kandidat za odbornika.</p>
            <h3>Član 23</h3>
            <p>Izborna komisija propisuje oblik i sadržaj obrasca za potpis birača koji podržavaju izbornu listu i stavlja ga na raspolaganje učesnicima u izborima u roku od tri dana od dana raspisivanja izbora.</p>
            <p>Birač može svojim potpisom podržati izbornu listu samo jednog predlagača.</p>
            <p>Potpis kojim birač podržava izbornu listu mora biti overen u skladu sa zakonom kojim se uređuje overa potpisa.</p>
            <p>Visinu naknade za overu potpisa utvrdiće posebnim aktom ministarstvo nadležno za poslove pravde.</p>
            <h3>Član 24</h3>
            <p>Izborna komisija proglašava izbornu listu, odmah po prijemu izborne liste i odgovarajuće dokumentacije, a najkasnije u roku od 24 časa od prijema izborne liste.</p>
            <p>Rešenje o proglašenju izborne liste iz stava 1. ovog člana izborna komisija dostavlja bez odlaganja podnosiocu.</p>
            <h3>Član 25</h3>
            <p>Kad izborna komisija utvrdi da izborna lista nije podneta blagovremeno, doneće rešenje o njenom odbacivanju.</p>
            <p>Kad izborna komisija utvrdi da izborna lista sadrži nedostatke koji su smetnja za proglašenje izborne liste u skladu s ovim zakonom, doneće, u roku od 24 časa od prijema izborne liste, zaključak kojim se podnosiocu izborne liste nalaže da najkasnije u roku od 48 časova od časa dostavljanja zaključka otkloni te nedostatke. Tim zaključkom istovremeno će se podnosiocu izborne liste ukazati na radnje koje treba da obavi radi otklanjanja nedostataka.</p>
            <p>Kad izborna komisija utvrdi da izborna lista sadrži nedostatke predviđene ovim zakonom, ili kad utvrdi da nedostaci izborne liste nisu otklonjeni, ili nisu otklonjeni u predviđenom roku, doneće u narednih 24 časa rešenje kojim se odbija proglašenje izborne liste.</p>
            <h3>Član 26</h3>
            <p>Zbirnu izbornu listu utvrđuje izborna komisija i ona sadrži sve izborne liste, sa ličnim imenima svih kandidata i podacima o godini rođenja, zanimanju i prebivalištu.</p>
            <p>Redosled izbornih lista, sa imenima svih kandidata, na zbirnoj izbornoj listi utvrđuje se prema redosledu njihovog proglašavanja.</p>
            <p>Zbirnu izbornu listu izborna komisija objavljuje na način kako se objavljuju propisi jedinice lokalne samouprave, najkasnije deset dana pre dana održavanja izbora.</p>
            <p>Svaki podnosilac izborne liste ima pravo da u roku od 48 časova od dana objavljivanja zbirne izborne liste izvrši, preko lica koje ovlasti, uvid u sve podnete izborne liste i dokumentaciju podnetu uz njih.</p>
            <h3>Član 27</h3>
            <p>Građani imaju pravo da budu obavešteni o izbornim listama.</p>
            <p>Građani imaju pravo da, preko javnih glasila, budu blagovremeno, istinito i nepristrasno obavešteni o svim aktivnostima učesnika izbornog postupka i događajima značajnim za izbore.</p>
            <h3>Sprovođenje izbora</h3>
            <h3>Član 28</h3>
            <p>Izborna komisija dužna je da za svaki birački odbor blagovremeno pripremi materijal za glasanje, a naročito potreban broj glasačkih listića, izvod iz biračkog spiska, potvrde o izbornom pravu, posebne službene koverte za glasanje, kao i obrazac zapisnika o radu biračkog odbora.</p>
            <p>Glasačke listiće priprema i overava izborna komisija.</p>
            <p>Izborna komisija utvrđuje ukupan broj glasačkih listića, koji mora biti jednak broju birača upisanih u birački spisak u opštini.</p>
            <p>Izborna komisija kontroliše pripremu i overu glasačkih listića i određuje broj rezervnih glasačkih listića, koji ne može biti veći od 0,3% od ukupnog broja glasačkih listića.</p>
            <p>Glasački listići štampaju se na jednom mestu.</p>
            <p>Podnosilac izborne liste dostavlja izbornoj komisiji ime lica koje ima pravo da prisustvuje štampanju, brojanju, pakovanju glasačkih listića i njihovom dostavljanju organima nadležnim za sprovođenje izbora.</p>
            <p>U opštinama gde su u službenoj upotrebi jezici nacionalnih manjina glasački listići se štampaju i na tim jezicima.</p>
            <p>Izborna komisija bliže utvrđuje sadržinu, oblik i izgled glasačkih listića, određuje štampara, način i kontrolu njihovog štampanja i dostavljanje i rukovanje glasačkim listićima.</p>
            <h3>Član 29</h3>
            <p>Primopredaja izbornog materijala vrši se najkasnije 24 časa pre dana održavanja izbora.</p>
            <p>Opštinska uprava stara se o uređivanju biračkih mesta i priprema za svaki birački odbor potreban broj glasačkih kutija s priborom za njihovo pečaćenje, paravane za glasanje i pribor za pisanje.</p>
            <p>Na dan izbora, pre početka glasanja, birački odbor utvrđuje da li je pripremljeni izborni materijal za to biračko mesto potpun i ispravan, da li je biračko mesto uređeno na način kojim se obezbeđuje tajnost glasanja i da li glasanje može početi, što unosi u zapisnik o svom radu.</p>
            <h3>Član 30</h3>
            <p>Glasački listić sadrži:</p>
            <p>1) redni broj koji se stavlja ispred naziva izborne liste;</p>
            <p>2) nazive izbornih lista, prema redosledu utvrđenom na zbirnoj listi, sa ličnim imenom prvog kandidata sa liste;</p>
            <p>3) napomenu da se glasa samo za jednu izbornu listu, zaokruživanjem rednog broja ispred naziva te liste ili naziva liste.</p>
            <h3>Član 31</h3>
            <p>Zbirna izborna lista, s nazivima izbornih lista i imenima svih kandidata, mora za vreme glasanja biti vidno istaknuta na biračkom mestu.</p>
            <p>Sadržaj, oblik i način isticanja zbirne izborne liste iz stava 1. ovog člana propisuje izborna komisija.</p>
            <h3>Član 32</h3>
            <p>Predstavnici podnosilaca izbornih lista i kandidati za odbornike imaju pravo uvida u izborni materijal, a naročito u izvode iz biračkih spiskova, zapisnike biračkog odbora, zapisnike izbornih komisija i glasačke listiće. Uvid se vrši u službenim prostorijama izborne komisije. Uvid u izborni materijal može se izvršiti u roku od pet dana od dana održavanja izbora.</p>
            <h3>Član 33</h3>
            <p>Birač glasa na biračkom mestu na kome je upisan u birački spisak.</p>
            <p>Birač može da glasa i van biračkog mesta na kom je upisan u izvod iz biračkog spiska, pod uslovima i na način utvrđen zakonom.</p>
            <p>Način glasanja van biračkog mesta, kao i broj birača koji su glasali na taj način unosi se u zapisnik o radu biračkog odbora.</p>
            <h3>Član 34</h3>
            <p>Birač glasa lično.</p>
            <p>Birač može glasati samo za jednu od izbornih lista sa glasačkog listića.</p>
            <p>Glasa se zaokruživanjem rednog broja ispred naziva izborne liste za koju se glasa ili zaokruživanjem naziva izborne liste, odnosno tako da se nedvosmisleno može utvrditi za koju izbornu listu je birač glasao.</p>
            <p>Birač sam presavija glasački listić tako da se ne vidi za koju je izbornu listu glasao i ubacuje ga u glasačku kutiju i odmah napušta biračko mesto.</p>
            <h3>Utvrđivanje i objavljivanje rezultata izbora</h3>
            <h3>Član 35</h3>
            <p>Po završenom glasanju birački odbor pristupa utvrđivanju rezultata glasanja na biračkom mestu.</p>
            <p>Birački odbor utvrđuje broj neupotrebljenih glasačkih listića i stavlja ih u poseban omot koji pečati, s naznakom na omotu da se radi o neupotrebljenim glasačkim listićima.</p>
            <p>Na osnovu izvoda iz biračkog spiska, birački odbor utvrđuje ukupan broj birača koji su glasali.</p>
            <p>Kad se glasačka kutija otvori, posle provere kontrolnog lista, važeći glasački listići odvajaju se od nevažećih.</p>
            <p>Birački odbor konstatuje broj nevažećih glasačkih listića, unosi ga u zapisnik i nevažeće glasačke listiće pečati u poseban omot, s naznakom da se radi o nevažećim glasačkim listićima, a potom utvrđuje broj važećih listića i broj glasova za svaku izbornu listu, što takođe unosi u zapisnik.</p>
            <p>Važeći glasački listići stavljaju se u poseban omot sa naznakom da se radi o važećim glasačkim listićima, koji se potom pečati.</p>
            <p>Nevažeći glasački listić jeste nepopunjeni glasački listić, listić koji je popunjen tako da se ne može utvrditi za koju se izbornu listu glasalo i listić na kome je zaokruženo više od jedne izborne liste.</p>
            <p>Kad je na glasačkom listiću zaokruženo ime i prezime prvog kandidata na izbornoj listi ili je zaokružen naziv ili deo naziva izborne liste, odnosno ako su istovremeno zaokruženi redni broj i naziv izborne liste i ime i prezime prvog kandidata, takav izborni listić smatra se važećim.</p>
            <p>Kad se utvrdi da je broj glasačkih listića u glasačkoj kutiji veći od broja birača koji su glasali, ili u glasačkoj kutiji nije nađen kontrolni listić, birački odbor se raspušta i imenuje novi, a glasanje na tom biračkom mestu ponavlja se.</p>
            <h3>Član 36</h3>
            <p>Birački odbor, po utvrđivanju rezultata glasanja, u zapisnik o svom radu unosi: broj primljenih glasačkih listića; broj neupotrebljenih glasačkih listića; broj nevažećih glasačkih listića; broj važećih glasačkih listića; broj glasova datih za svaku izbornu listu; broj birača prema izvodu iz biračkog spiska i broj birača koji su glasali.</p>
            <p>U zapisnik o radu biračkog odbora unose se i primedbe i mišljenja članova biračkog odbora, podnosilaca izbornih lista i zajedničkih predstavnika podnosilaca izbornih lista, kao i sve druge činjenice od značaja za glasanje.</p>
            <p>Zapisnik o radu biračkog odbora potpisuju svi članovi biračkog odbora.</p>
            <h3>Član 37</h3>
            <p>Zapisnik o radu biračkog odbora izrađuje se na propisanom obrascu koji se štampa u šest primeraka.</p>
            <p>U opštinama gde su u službenoj upotrebi jezici nacionalnih manjina zapisnik o radu biračkog odbora štampa se i na tim jezicima.</p>
            <p>Prvi primerak zapisnika s utvrđenim izbornim materijalom dostavlja se izbornoj komisiji.</p>
            <p>Drugi primerak zapisnika ističe se na biračkom mestu na javni uvid.</p>
            <p>Preostala četiri primerka zapisnika uručuju se predstavnicima podnosilaca izbornih lista koje su osvojile najveći broj glasova na tom biračkom mestu i to odmah ukoliko podnosilac izborne liste ima predstavnika u biračkom odboru, a ukoliko ga nema predstavnik podnosioca izborne liste može preuzeti primerak zapisnika od izborne komisije u roku od 24 časa.</p>
            <p>Ostali podnosioci izborne liste imaju pravo da, u roku od 24 časa od dostavljanja materijala s biračkog mesta izbornoj komisiji, od izborne komisije dobiju overenu fotokopiju zapisnika s biračkog mesta.</p>
            <h3>Član 38</h3>
            <p>Kad utvrdi rezultate glasanja, birački odbor će bez odlaganja, a najkasnije u roku od osam časova od zatvaranja biračkih mesta, dostaviti izbornoj komisiji zapisnik o utvrđivanju rezultata glasanja na biračkom mestu, izvod iz biračkog spiska, važeće glasačke listiće u zapečaćenom posebnom omotu, neupotrebljene glasačke listiće u zapečaćenom posebnom omotu, nevažeće glasačke listiće u zapečaćenom posebnom omotu, kao i preostalu izbornu dokumentaciju.</p>
            <p>O primopredaji izborne dokumentacije sastavlja se zapisnik koji potpisuje predstavnik izborne komisije i najmanje dva člana biračkog odbora koji su predali izbornu dokumentaciju.</p>
            <h3>Član 39</h3>
            <p>Po prijemu izbornog materijala sa biračkih mesta izborna komisija, u roku od 24 časa od zatvaranja biračkih mesta, utvrđuje: ukupan broj birača upisanih u birački spisak; broj birača koji su glasali na biračkim mestima; broj birača koji su glasali van biračkog mesta; ukupan broj primljenih glasačkih listića; ukupan broj nevažećih glasačkih listića; ukupan broj važećih glasačkih listića i broj glasova datih za svaku izbornu listu.</p>
            <p>Izborna komisija utvrđuje rezultate glasanja na biračkim mestima najkasnije u roku od šest časova od dostavljanja izveštaja sa biračkih mesta.</p>
            <h3>Član 40</h3>
            <p>Izborna komisija utvrđuje rezultate izbora i o tome sačinjava poseban zapisnik.</p>
            <p>Svakoj izbornoj listi pripada broj mandata koji je srazmeran broju dobijenih glasova.</p>
            <p>Izborna komisija utvrđuje broj mandata koji pripada svakoj izbornoj listi.</p>
            <p>U raspodeli mandata učestvuju izborne liste koje su dobile najmanje 3% glasova od ukupnog broja glasova birača koji su glasali.</p>
            <p>Političke stranke nacionalnih manjina i koalicije političkih stranaka nacionalnih manjina učestvuju u raspodeli mandata i kad su dobile manje od 3% glasova od ukupnog broja birača koji su glasali, pri čemu se prilikom raspodele mandata primenom sistema najvećeg količnika, količnici svih izbornih lista političkih stranaka nacionalnih manjina i koalicija političkih stranaka nacionalnih manjina uvećavaju za 35%.</p>
            <p>Politička stranka nacionalne manjine, u smislu stava 5. ovog člana, jeste ona stranka za koju je izborna komisija utvrdila da joj je osnovni cilj predstavljanje i zastupanje interesa nacionalne manjine i zaštita i poboljšanje prava pripadnika nacionalne manjine, u skladu sa međunarodno pravnim standardima.</p>
            <p>O tome da li podnosilac izborne liste ima položaj političke stranke nacionalne manjine ili koalicije političkih stranaka nacionalnih manjina, u smislu stava 5. ovog člana, odlučuje izborna komisija posebnim rešenjem pri proglašenju izborne liste, a na predlog podnosioca izborne liste koji mora biti dostavljen pri podnošenju izborne liste. Izborna komisija može zatražiti mišljenje nadležnog nacionalnog saveta nacionalne manjine o tome da li je podnosilac izborne liste politička stranka nacionalne manjine ili koalicija političkih stranaka nacionalnih manjina.</p>
            <p>Podnosilac izborne liste može imati položaj političke stranke nacionalne manjine, u smislu stava 5. ovog člana, samo ako prema podacima poslednjeg popisa stanovništva na teritoriji jedinice lokalne samouprave žive pripadnici nacionalne manjine koju reprezentuje.</p>
            <h3>Član 41</h3>
            <p>Izborna komisija raspodeljuje mandate primenom sistema najvećeg količnika.</p>
            <p>Mandati se raspodeljuju tako što se ukupan broj glasova koji je dobila svaka pojedina lista podeli brojevima od jedan zaključno sa brojem koji odgovara broju odbornika koji se biraju u skupštinu jedinice. Tako dobijeni količnici razvrstavaju se po veličini, a u obzir se uzima onoliko najvećih količnika koliko se bira odbornika u skupštinu jedinice lokalne samouprave. Svaka izborna lista dobija onoliko mandata koliko tih količnika na nju otpada.</p>
            <p>Ako dve izborne liste ili više izbornih lista dobiju iste količnike na osnovu kojih se dodeljuje jedan mandat, a nema više neraspodeljenih mandata, mandat će se dodeliti listi koja je dobila veći broj glasova.</p>
            <p>Izborna komisija u roku od 24 časa od zatvaranja biračkih mesta vrši raspodelu odborničkih mandata na način iz stava 1. ovog člana, o čemu se sastavlja poseban zapisnik.</p>
            <h3>Član 42</h3>
            <p>Mandati koji pripadaju određenoj izbornoj listi dodeljuju se kandidatima sa te liste u skladu sa odredbama ovog zakona.</p>
            <h3>Član 43</h3>
            <p>Izborna komisija jedinice lokalne samouprave će najkasnije u roku od 10 dana od dana objavljivanja ukupnih rezultata izbora sve dobijene mandate sa izborne liste dodeliti kandidatima po redosledu na izbornoj listi, počev od prvog kandidata sa liste.</p>
            <h3>Član 44</h3>
            <p>Izborna komisija objavljuje rezultate izbora u roku od 24 časa od zatvaranja biračkih mesta.</p>
            <h3>Član 45</h3>
            <p>Izborna komisija izdaje odborniku uverenje da je izabran.</p>
            <h3>Prestanak mandata i ponovni izbori</h3>
            <h3>Član 46</h3>
            <p>Odborniku prestaje mandat pre isteka vremena na koje je izabran:</p>
            <p>1) podnošenjem ostavke;</p>
            <p>2) donošenjem odluke o raspuštanju skupštine jedinice lokalne samouprave;</p>
            <p>3) ako je pravnosnažnom sudskom odlukom osuđen na bezuslovnu kaznu zatvora u trajanju od najmanje šest meseci;</p>
            <p>4) ako je pravnosnažnom odlukom lišen poslovne sposobnosti;</p>
            <p>5) preuzimanjem posla, odnosno funkcije koje su, u skladu sa zakonom, nespojive s funkcijom odbornika;</p>
            <p>6) ako mu prestane prebivalište na teritoriji jedinice lokalne samouprave;</p>
            <p>7) gubljenjem državljanstva;</p>
            <p>8) ako nastupi smrt odbornika.</p>
            <p>Odbornik može podneti ostavku usmeno na sednici skupštine jedinice lokalne samouprave, a između dve sednice podnosi je u formi overene pisane izjave.</p>
            <p>Posle podnošenja usmene ostavke odbornika, skupština bez odlaganja, na istoj sednici utvrđuje da je odborniku prestao mandat.</p>
            <p>O ostavci koju je odbornik podneo između dve sednice, skupština je dužna da odluči na prvoj narednoj sednici.</p>
            <p>Odbornik može opozvati podnetu ostavku sve dok skupština ne utvrdi prestanak njegovog mandata.</p>
            <p>Ako odborniku prestaje mandat nastupanjem slučaja iz stava 1. ovog člana tač. 2) do 8), skupština jedinice lokalne samouprave na prvoj narednoj sednici, posle obaveštenja o nastupanju takvog slučaja, utvrđuje da je odborniku prestao mandat.</p>
            <h3>Član 47</h3>
            <p>Odbornik lično podnosi ostavku, overenu kod organa nadležnog za overu potpisa, predsedniku skupštine jedinice lokalne samouprave, u roku od tri dana od dana overe.</p>
            <p>Predsednik skupštine jedinice lokalne samouprave je dužan da podnetu ostavku stavi na dnevni red skupštine na prvoj narednoj sednici, sa predlogom da to bude prva tačka dnevnog reda.</p>
            <h3>Član 48</h3>
            <p>Kada odborniku prestane mandat pre isteka vremena na koje je izabran, mandat se dodeljuje prvom sledećem kandidatu sa iste izborne liste kome nije bio dodeljen mandat odbornika.</p>
            <p>Kada odborniku koji je izabran sa koalicione izborne liste prestane mandat pre isteka vremena na koje je izabran, mandat se dodeljuje prvom sledećem kandidatu na izbornoj listi kome nije bio dodeljen mandat - pripadniku iste političke stranke.</p>
            <p>Kandidatu kome je bio dodeljen mandat odbornika, a kojem je mandat prestao zbog preuzimanja funkcije predsednika opštine ili gradonačelnika, odnosno zamenika predsednika opštine ili zamenika gradonačelnika, mandat se ponovo dodeljuje u istom sazivu skupštine jedinice lokalne samouprave pod uslovima:</p>
            <ul>
              <li>
                  <p>da je kandidatu prestala funkcija predsednika opštine ili gradonačelnika, odnosno zamenika predsednika opštine ili zamenika gradonačelnika;</p>
              </li>
              <li>
                  <p>da postoji upražnjeno odborničko mesto koje pripada istoj izbornoj listi, i</p>
              </li>
              <li>
                  <p>da je izbornoj komisiji jedinice lokalne samouprave kandidat podneo zahtev za dodelu mandata odbornika.</p>
              </li>
            </ul>
            <p>Kad odborniku prestane mandat pre isteka vremena na koje je izabran, a na izbornoj listi sa koje je odbornik bio izabran nema kandidata za koje podnosilac izborne liste nije dobio mandat, mandat pripada podnosiocu izborne liste koji ima sledeći najveći količnik a za njega nije dobio mandat.</p>
            <p>Mandat novog odbornika traje do isteka mandata odbornika kome je prestao mandat.</p>
            <p>Od kandidata se pre potvrđivanja mandata pribavlja pismena saglasnost da prihvata mandat.</p>
            <h3>Član 49</h3>
            <p>Na odluke skupštine jedinice lokalne samouprave o prestanku mandata odbornika, kao i o potvrđivanju mandata novom odborniku, dopuštena je žalba Upravnom sudu.</p>
            <p>Žalba je dopuštena i u slučaju kada skupština propusti da donese odluke iz stava 1. ovog člana.</p>
            <p>U slučaju osnovanosti žalbe iz stava 2. ovog člana, sud donosi odluku kojom utvrđuje prestanak mandata odbornika, odnosno potvrđuje mandat novom odborniku.</p>
            <p>Pravo na podnošenje žalbe iz stava 2. ovog člana ima svaki odbornik.</p>
            <p>Žalba se podnosi u roku od 48 časova od dana donošenja odluke skupštine jedinice lokalne samouprave, odnosno od dana održavanja sednice na kojoj je skupština propustila da donese odluke iz stava 1. ovog člana.</p>
            <p>Upravni sud dužan je da u roku od 30 dana od dana dobijanja žalbe donese rešenje o podnetoj žalbi.</p>
            <h3>Član 50</h3>
            <p>Ponovni izbori sprovode se ako izborna komisija ili Upravni sud ponište izbore zbog nepravilnosti u sprovođenju izbora, u slučajevima utvrđenim ovim zakonom.</p>
            <p>Ako se izbori ponište na pojedinom biračkom mestu, glasanje se ponavlja samo na tom biračkom mestu.</p>
            <p>Na biračkim mestima na kojima izborni postupak nije sproveden u skladu s ovim zakonom, izbori se ponavljaju u roku od sedam dana od utvrđivanja nepravilnosti u izbornom postupku, na način i po postupku utvrđenim za sprovođenje izbora.</p>
            <p>Ponovne izbore raspisuje izborna komisija.</p>
            <p>Ponovni izbori sprovode se po listi kandidata koja je utvrđena za izbore koji su poništeni, osim kad su izbori poništeni zbog nepravilnosti u utvrđivanju izborne liste.</p>
            <p>U slučaju ponavljanja izbora konačni rezultati izbora utvrđuju se po završetku ponovljenog glasanja.</p>
            <h3>Zaštita izbornog prava</h3>
            <h3>Član 51</h3>
            <p>Svaki birač, kandidat za odbornika i predlagač kandidata ima pravo na zaštitu izbornog prava, po postupku utvrđenom ovim zakonom.</p>
            <h3>Član 52</h3>
            <p>Birač, kandidat za odbornika ili predlagač kandidata ima pravo da podnese prigovor izbornoj komisiji jedinice lokalne samouprave zbog nepravilnosti u postupku kandidovanja, sprovođenja, utvrđivanja i objavljivanja rezultata izbora.</p>
            <p>Prigovor se podnosi u roku od 24 časa od dana kada je doneta odluka, odnosno izvršena radnja ili učinjen propust.</p>
            <h3>Član 53</h3>
            <p>Izborna komisija doneće rešenje u roku od 48 časova od prijema prigovora i dostaviti ga podnosiocu prigovora.</p>
            <p>Ako izborna komisija jedinice lokalne samouprave usvoji podneti prigovor, poništiće odluku ili radnju.</p>
            <h3>Član 54</h3>
            <p>Protiv rešenja izborne komisije, može se izjaviti žalba Upravnom sudu u roku od 24 časa od dostavljanja rešenja.</p>
            <p>Izborna komisija dužna je da sudu dostavi odmah, a najkasnije u roku od 12 časova sve potrebne podatke i spise za odlučivanje.</p>
            <p>U postupku zaštite izbornog prava sud shodno primenjuje odredbe zakona kojim se uređuje postupak u upravnim sporovima.</p>
            <p>Odluka po žalbi doneće se najkasnije u roku od 48 časova od dana prijema žalbe sa spisima.</p>
            <p>Odluka doneta u postupku po žalbi je pravnosnažna i protiv nje se ne mogu podneti zahtev za vanredno preispitivanje sudske odluke, niti zahtev za ponavljanje postupka, predviđeni Zakonom o upravnim sporovima.</p>
            <h3>Član 55</h3>
            <p>Ako sud usvoji žalbu, poništiće odluku ili radnju u postupku predlaganja kandidata, odnosno u postupku izbora odbornika ili će poništiti izbor odbornika.</p>
            <p>Kada nađe da osporenu odluku treba poništiti, ako priroda stvari to dozvoljava i ako utvrđeno činjenično stanje pruža pouzdan osnov za to, sud može svojom odlukom meritorno rešiti izborni spor. Odluka suda u svemu zamenjuje poništeni akt.</p>
            <p>Ako je po prigovoru, odnosno po žalbi, poništena radnja u postupku izbora ili izbor odbornika, izborna komisija je dužna da odgovarajuću izbornu radnju, odnosno izbore ponovi u roku predviđenom ovim zakonom za ponovne izbore. Rok se računa od dana donošenja odluke o poništavanju.</p>
            <h3>Član 56</h3>
            <p>Mandat odbornika počinje da teče danom potvrđivanja mandata.</p>
            <p>O potvrđivanju mandata odbornika odlučuje skupština na konstitutivnoj sednici.</p>
            <p>Konstitutivnu sednicu skupštine saziva predsednik skupštine iz prethodnog saziva u roku od 15 dana od dana objavljivanja rezultata izbora.</p>
            <p>Konstitutivnom sednicom predsedava najstariji odbornik, koji je ovlašćen i da sazove konstitutivnu sednicu ako to u zakonskom roku ne učini predsednik skupštine iz prethodnog saziva.</p>
            <p>O potvrđivanju mandata odbornika, na osnovu izveštaja verifikacionog odbora, skupština odlučuje javnim glasanjem. U glasanju mogu učestvovati kandidati za odbornike kojima su mandati dodeljeni u skladu sa članom 43. ovog zakona i koji imaju uverenje izborne komisije jedinice lokalne samouprave da su izabrani.</p>
            <p>Kada skupština posle konstituisanja odlučuje o potvrđivanju mandata novih odbornika, u glasanju, pored odbornika, mogu učestvovati i kandidati kojima su mandati dodeljeni u skladu sa članom 48. ovog zakona i koji imaju uverenje izborne komisije jedinice lokalne samouprave da su izabrani.</p>
            <p>Protiv odluke donete u vezi sa potvrđivanjem mandata odbornika može se izjaviti žalba Upravnom sudu u roku od 48 časova od dana donošenja odluke skupštine jedinice lokalne samouprave.</p>
            <h3>Troškovi sprovođenja izbora</h3>
            <h3>Član 57</h3>
            <p>Sredstva za sprovođenje izbora i finansiranje izborne aktivnosti za izbor odbornika skupštine jedinice lokalne samouprave obezbeđuju se u budžetu jedinice lokalne samouprave.</p>
            <p>Na podneske i radnje u izbornom postupku ne plaća se taksa.</p>
            <h3>Primena propisa o izboru narodnih poslanika</h3>
            <h3>Član 58</h3>
            <p>Odredbe Zakona o izboru narodnih poslanika ("Službeni glasnik RS", br. 35/00, 57/03 - odluka USRS, 72/03 - dr. zakon, 75/03 - ispr. dr. zakona, 18/04, 85/05 - dr. zakon i 101/05 - dr. zakon) o biračkim spiskovima, o izbornom materijalu, imenovanju u izborne komisije i biračke odbore, biračkim mestima, o obaveštavanju građana o predloženim kandidatima, o zabrani trajanja izborne propagande i objavljivanju prethodnih rezultata ili procene rezultata izbora, glasanju, utvrđivanju i objavljivanju rezultata izbora, neplaćanju poreza i doprinosa za sredstva koja se isplaćuju kao naknada za rad lica u organima za sprovođenje izbora i kaznama, shodno se primenjuju na izbor odbornika, ako ovim zakonom nije drugačije određeno.</p>
            <h2>III PRELAZNE I ZAVRŠNE ODREDBE</h2>
            <h3>Član 59</h3>
            <p>Odredbe ovog zakona o izboru odbornika skupština jedinica lokalne samouprave primenjivaće se od prvih narednih izbora za odbornike skupština jedinica lokalne samouprave u svim jedinicama lokalne samouprave.</p>
            <h3>Član 60</h3>
            <p>Prve izbore za odbornike skupština jedinica lokalne samouprave u svim jedinicama lokalne samouprave, po stupanju na snagu ovog zakona, raspisaće predsednik Narodne skupštine, tako da se oni održe u roku koji ne može biti kraći od 120 ni duži od 180 dana od dana raspisivanja izbora.</p>
            <h3>Član 61</h3>
            <p>Danom stupanja na snagu ovog zakona prestaje da važi Zakon o lokalnim izborima ("Službeni glasnik RS", br. 33/02, 37/02 - ispravka, 42/02, 72/03 - dr. zakon, 75/03 - ispr. dr. zakona, 100/03 - US i 101/05 - dr. zakon).</p>
            <h3>Član 62</h3>
            <p>Ovaj zakon stupa na snagu danom objavljivanja u "Službenom glasniku Republike Srbije".</p>
            <p>Samostalni član Zakona o izmenama i dopunama
              Zakona o lokalnim izborima
            </p>
            <h2>Samostalni član Zakona o izmenama i dopunama
            Zakona o lokalnim izborima</h2>
            <p>("Sl. glasnik RS", br. 54/2011)</p>
            <h3>Član 12</h3>
            <p>Ovaj zakon stupa na snagu osmog dana od dana objavljivanja u "Službenom glasniku Republike Srbije".</p>
            <h2>Samostalni član Zakona o izmenama i dopuni Zakona o lokalnim izborima</h2>
            <p>("Sl. glasnik RS", br. 12/2020)</p>
            <h3>Član 3</h3>
            <p>Ovaj zakon stupa na snagu narednog dana od dana objavljivanja u "Službenom glasniku Republike Srbije".</p>

        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Zakon o izboru narodnih poslanika
            </mat-panel-title>
          </mat-expansion-panel-header>
          
            <h2>I OSNOVNE ODREDBE</h2>
            <h3>Član 1</h3>
            <p>Ovim zakonom uređuju se izbor i prestanak mandata narodnih poslanika Narodne skupštine Republike Srbije (u daljem tekstu: poslanici).</p>
            <h3>Član 2</h3>
            <p>Građani biraju poslanike na osnovu slobodnog, opšteg, jednakog i neposrednog izbornog prava, tajnim glasanjem.</p>
            <p>Niko nema pravo da, po bilo kom osnovu, sprečava ili primorava građanina da glasa, da ga poziva na odgovornost zbog glasanja i da od njega traži da se izjasni za koga je glasao ili zašto nije glasao.</p>
            <h3>Član 3</h3>
            <p>Narodna skupština Republike Srbije ima 250 poslanika, koji se biraju na četiri godine.</p>
            <h3>Član 4</h3>
            <p>Poslanici se biraju u Republici Srbiji, kao jednoj izbornoj jedinici, na osnovu lista političkih stranaka, stranačkih koalicija, drugih političkih organizacija i lista koje predlože grupe građana (u daljem tekstu: izborna lista).</p>
            <p>Poslanički mandati raspodeljuju se srazmerno broju dobijenih glasova.</p>
            <h3>Član 5</h3>
            <p>Građani imaju pravo da preko sredstava javnog obaveštavanja budu obavešteni o izbornim programima i aktivnostima podnosilaca izbornih lista, kao i o kandidatima sa izbornih lista.</p>
            <p>Sredstva javnog obaveštavanja dužna su da obezbede ravnopravnost u obaveštavanju o svim podnosiocima izbornih lista i kandidatima sa tih lista.</p>
            <p>Izborna propaganda preko sredstava javnog obaveštavanja i javnih skupova i objavljivanje procene rezultata izbora zabranjeni su 48 časova pre dana održavanja izbora i na dan održavanja izbora do zatvaranja biračkih mesta.</p>
            <h3>Član 6</h3>
            <p>Organi za sprovođenje izbora su Republička izborna komisija i birački odbori.</p>
            <h3>Član 7</h3>
            <p>Zaštitu izbornog prava obezbeđuju Republička izborna komisija, Upravni sud i nadležni sudovi.</p>
            <h3>Član 8</h3>
            <p>Sredstva za sprovođenje izbora za poslanike obezbeđuju se u budžetu Republike Srbije.</p>
            <h2>II IZBORNO PRAVO</h2>
            <h3>Član 9</h3>
            <p>Izborno pravo u smislu ovog zakona obuhvata pravo građana da na način i po postupku koji je utvrđen ovim zakonom: biraju i budu birani; kandiduju i budu kandidovani, odlučuju o predloženim kandidatima i izbornim listama; da kandidatima javno postavljaju pitanja; da budu pravovremeno, istinito, potpuno i objektivno obavešteni o programima i aktivnostima podnosilaca izbornih lista i o kandidatima sa tih lista, kao i da raspolažu drugim pravima koja su predviđena ovim zakonom.</p>
            <h3>Član 10</h3>
            <p>Pravo da bira poslanika i da bude biran za poslanika ima punoletan poslovno sposoban državljanin Republike Srbije sa prebivalištem u Republici Srbiji (u daljem tekstu: birač).</p>
            <h3>Član 11</h3>
            <p>Poslanik ne može istovremeno biti nosilac pravosudne ili druge funkcije na koju ga bira Narodna skupština Republike Srbije, niti drugi funkcioner ili zaposleni koji u republičkom organu obavlja poslove koji se odnose na delokrug tog organa, osim u slučajevima utvrđenim Ustavom.</p>
            <p>Danom potvrđivanja poslaničkog mandata licu izabranom od Narodne skupštine Republike Srbije prestaje ta funkcija, a licu zaposlenom u republičkom organu počinje da miruje radni odnos.</p>
            <h2>III BIRAČKI SPISKOVI</h2>
            <h3>Čl. 12-24<strong>**</strong></h3>
            <p>(Prestali da važe)</p>
            <h2>IV RASPISIVANJE IZBORA</h2>
            <h3>Član 25</h3>
            <p>Izbore za poslanike raspisuje predsednik Republike. Odlukom o raspisivanju izbora određuju se dan održavanja izbora i dan od koga počinju teći rokovi za vršenje izbornih radnji.</p>
            <p>Odluka o raspisivanju izbora objavljuje se u "Službenom glasniku Republike Srbije".</p>
            <h3>Član 26</h3>
            <p>Od dana raspisivanja do dana održavanja izbora ne može proteći manje od 45 ni više od 60 dana.</p>
            <h3>Član 27</h3>
            <p>Izbori za poslanike održavaju se najkasnije 30 dana pre isteka mandata poslanika kojima ističe mandat.</p>
            <p>Danom potvrđivanja mandata novoizabranim poslanicima prestaje funkcija poslanika čiji mandat ističe.</p>
            <p>Potvrđivanje mandata poslanika vrši se na osnovu uverenja o izboru za narodnog poslanika i izveštaja Republičke izborne komisije o sprovedenim izborima, u roku od 30 dana od dana proglašenja konačnih rezultata izbora.</p>
            <p>Na konstitutivnoj sednici Narodne skupštine Republike Srbije obrazuje se komisija od tri člana radi utvrđivanja saglasnosti uverenja o izboru za narodnog poslanika i izveštaja Republičke izborne komisije.</p>
            <p>Na osnovu izveštaja komisije iz stava 4. ovog člana lice koje predsedava konstitutivnom sednicom Narodne skupštine Republike Srbije konstatuje da je Republička izborna komisija podnela izveštaj o sprovedenim izborima i koja su uverenja o izboru za narodne poslanike u saglasnosti sa tim izveštajem, čime je potvrđen mandat novoizabranim poslanicima.</p>
            <h2>V ORGANI ZA SPROVOĐENJE IZBORA</h2>
            <h3>Položaj organa</h3>
            <h3>Član 28</h3>
            <p>Organi za sprovođenje izbora su samostalni i nezavisni u radu i rade na osnovu zakona i propisa donetih na osnovu zakona.</p>
            <p>Za svoj rad organi za sprovođenje izbora odgovaraju organu koji ih je imenovao.</p>
            <p>Svi državni i drugi organi i organizacije dužni su da pružaju pomoć organima za sprovođenje izbora i da im dostavljaju podatke potrebne za rad.</p>
            <h3>Član 29</h3>
            <p>Organi za sprovođenje izbora rade u stalnom i proširenom sastavu.</p>
            <p>Organi za sprovođenje izbora rade u proširenom sastavu od dana utvrđivanja tog sastava do završetka izbora.</p>
            <p>Organi za sprovođenje izbora odlučuju većinom glasova članova u stalnom, odnosno proširenom sastavu.</p>
            <p>Nijedna politička stranka, stranačka koalicija ili druga politička organizacija ne mogu imati više od polovine članova u stalnom sastavu svih organa za sprovođenje izbora.</p>
            <h3>Član 30</h3>
            <p>Članovi Republičke izborne komisije i njihovi zamenici imenuju se na četiri godine, a članovi biračkih odbora i njihovi zamenici za svake izbore.</p>
            <p>Članovi i zamenici članova organa za sprovođenje izbora ne mogu biti lica koja su međusobno srodnici po pravoj liniji bez obzira na stepen srodstva, u pobočnoj zaključno sa trećim stepenom srodstva, a u tazbinskom srodstvu zaključno sa drugim stepenom srodstva, kao ni bračni drugovi i lica koja su u međusobnom odnosu usvojioca i usvojenika, odnosno staraoca i štićenika.</p>
            <p>Ako je izborna komisija, odnosno birački odbor sastavljen suprotno odredbi stava 2. ovog člana taj organ se raspušta, a izbori, odnosno glasanje se ponavljaju.</p>
            <p>Zamenici članova Republičke izborne komisije i članova biračkih odbora imaju ista prava i odgovornosti kao i članovi koje zamenjuju.</p>
            <p>Pravo glasa u Republičkoj izbornoj komisiji ili biračkom odboru ima samo član tog organa, ili, u njegovom odsustvu, zamenik.</p>
            <h3>Član 31</h3>
            <p>Članovi organa za sprovođenje izbora poslanika i njihovi zamenici moraju imati izborno pravo.</p>
            <p>Članovima organa za sprovođenje izbora i njihovim zamenicima prestaje funkcija u ovim organima kad prihvate kandidaturu za poslanike.</p>
            <h3>Član 32</h3>
            <p>Rad organa za sprovođenje izbora javan je.</p>
            <p>Lica koja prate rad organa za sprovođenje izbora dužna su da postupaju u skladu s pravilima koja propisuje Republička izborna komisija.</p>
            <p>Kad lica iz stava 2. ovog člana prekrše pravila o održavanju reda na biračkom mestu ili na drugi način ometaju rad organa za sprovođenje izbora, organ za sprovođenje izbora može ih udaljiti, a podatke o tome unosi u zapisnik.</p>
            <p>Kandidat sa izborne liste koja je potvrđena i proglašena ne može prisustvovati radu organa za sprovođenje izbora.</p>
            <h3>Republička izborna komisija</h3>
            <h3>Član 33</h3>
            <p>Republičku izbornu komisiju u stalnom sastavu čine predsednik i šesnaest članova koje imenuje Narodna skupština Republike Srbije na predlog poslaničkih grupa u Narodnoj skupštini Republike Srbije, a u proširenom sastavu i po jedan predstavnik podnosioca izborne liste.</p>
            <p>Republička izborna komisija ima sekretara koga imenuje Narodna skupština Republike Srbije iz reda stručnih radnika svoje službe, koji učestvuje u radu komisije bez prava odlučivanja.</p>
            <p>Republička izborna komisija ima i jednog člana, predstavnika republičke organizacije nadležne za poslove statistike, koji učestvuje u radu komisije bez prava odlučivanja.</p>
            <p>Predsednik, članovi Republičke izborne komisije i njen sekretar imaju zamenike.</p>
            <p>Predsednik, članovi Republičke izborne komisije i njihovi zamenici moraju biti diplomirani pravnici.</p>
            <p>Republička izborna komisija rešenjem utvrđuje da podnosilac izborne liste ispunjava uslove za određivanje svojih predstavnika u prošireni sastav ovog organa u roku od 48 časova od proglašenja izborne liste.</p>
            <p>Rešenje o ispunjenju, odnosno neispunjenju uslova za određivanje predstavnika podnosioca izborne liste Republička izborna komisija dostavlja podnosiocu izborne liste u roku od 24 časa od časa donošenja rešenja.</p>
            <p>Predstavnik podnosioca izborne liste iz stava 1. ovog zakona postaje član Republičke izborne komisije u proširenom sastavu najkasnije 24 časa od donošenja rešenja.</p>
            <p>Sastav Republičke izborne komisije objavljuje se u "Službenom glasniku Republike Srbije".</p>
            <h3>Član 34</h3>
            <p>Republička izborna komisija:</p>
            <ol>
              <li>
                  <p>stara se o zakonitom sprovođenju izbora,</p>
              </li>
              <li>
                  <p>organizuje tehničku pripremu za izbore,</p>
              </li>
              <li>
                  <p>prati primenu i daje objašnjenja u vezi sa primenom ovog zakona, naročito o sprovođenju glasanja u inostranstvu,</p>
              </li>
              <li>
                  <p>utvrđuje jedinstvene standarde za izborni materijal,</p>
              </li>
              <li>
                  <p>propisuje obrasce i pravila za sprovođenje izbornih radnji propisanih ovim zakonom,</p>
              </li>
              <li>
                  <p>propisuje vrstu spreja iz člana 68. stav 4. ovog zakona,</p>
              </li>
              <li>
                  <p>određuje i oglašava u "Službenom glasniku Republike Srbije" broj i adresu biračkih mesta, najkasnije 20 dana pre dana određenog za održavanje izbora,</p>
              </li>
              <li>
                  <p>obrazuje biračke odbore i imenuje predsednika i članove biračkih odbora.</p>
              </li>
              <li>
                  <p>utvrđuje broj glasačkih listića za biračka mesta, overava ih i zajedno sa overenim izvodom iz biračkog spiska, zapisnički, predaje biračkim odborima,</p>
              </li>
              <li>
                  <p>određuje izborne akte koji joj se dostavljaju,</p>
              </li>
              <li>
                  <p>utvrđuje da li je izborna lista sastavljena i podneta u skladu sa ovim zakonom i proglašava,</p>
              </li>
              <li>
                  <p>donosi rešenje o proglašenju zbirne izborne liste,</p>
              </li>
              <li>
                  <p>određuje način čuvanja i rukovanja izbornim materijalom,</p>
              </li>
              <li>
                  <p>utvrđuje i objavljuje rezultate izbora,</p>
              </li>
              <li>
                  <p>utvrđuje broj mandata koji pripadaju svakoj izbornoj listi,</p>
              </li>
              <li>
                  <p>podnosi izveštaj Narodnoj skupštini Republike Srbije o sprovedenim izborima,</p>
              </li>
              <li>
                  <p>dostavlja podatke organima nadležnim za prikupljanje i obradu statističkih podataka,</p>
              </li>
              <li>
                  <p>obavlja i druge poslove predviđene ovim zakonom.</p>
              </li>
            </ol>
            <p>Republička izborna komisija donosi poslovnik o svom radu.</p>
            <p>Obrasce iz tač. 4. i 5. ovog člana Republička izborna komisija je dužna da u roku od pet dana od dana raspisivanja izbora stavi na raspolaganje učesnicima u izborima.</p>
            <h3>Član 35</h3>
            <p>Uslove za rad Republičke izborne komisije obezbeđuje Narodna skupština Republike Srbije.</p>
            <h3>Birački odbor</h3>
            <h3>Član 36</h3>
            <p>Birački odbor u stalnom sastavu čine: predsednik i najmanje dva člana, a u proširenom sastavu i po jedan predstavnik podnosioca izborne liste.</p>
            <p>Predsednik i članovi biračkog odbora imaju zamenike.</p>
            <p>Birački odbor imenuje se najdocnije 10 dana pre dana određenog za održavanje izbora.</p>
            <p>Republička izborna komisija, u roku od 48 časova od časa donošenja rešenja o proglašenju izborne liste, rešenjem utvrđuje koji podnosilac izborne liste ispunjava uslove za određivanje svojih predstavnika u prošireni sastav biračkog odbora.</p>
            <p>Rešenje o ispunjenju, odnosno neispunjenju uslova za određivanje predstavnika podnosioca izborne liste Republička izborna komisija dostavlja podnosiocu izborne liste u roku od 24 časa od časa donošenja tog rešenja.</p>
            <p>Predstavnik podnosioca izborne liste iz stava 1. ovog člana postaje član biračkog odbora u proširenom sastavu najkasnije 24 sata od časa donošenja tog rešenja.</p>
            <p>Podnosioci izbornih lista mogu se sporazumeti da u birački odbor odrede zajedničkog predstavnika.</p>
            <h3>Član 37</h3>
            <p>Birački odbor neposredno sprovodi glasanje na biračkom mestu, obezbeđuje pravilnost i tajnost glasanja, utvrđuje rezultate glasanja na biračkom mestu i obavlja druge poslove određene ovim zakonom.</p>
            <p>Birački odbor se stara o održavanju reda na biračkom mestu za vreme glasanja.</p>
            <p>Bliža pravila o radu biračkog odbora propisuje Republička izborna komisija.</p>
            <h3>Član 38</h3>
            <p>U slučaju istovremenog održavanja izbora za narodne poslanike Narodne skupštine Republike Srbije i predsednika Republike birački odbori obrazovani za sprovođenje izbora za narodne poslanike obavljaju poslove biračkih odbora za izbor predsednika Republike.</p>
            <h3>Predstavnici podnosilaca izbornih lista u organima za sprovođenje izbora</h3>
            <h3>Član 39</h3>
            <p>Podnosilac izborne liste iz čl. 33. i 36. ovog zakona određuje predstavnike u organima za sprovođenje izbora i o tome obaveštava organe koji su imenovali organe za sprovođenje izbora.</p>
            <p>Pošto dobiju obaveštenje o licima koja ulaze u proširen sastav, organi za sprovođenje izbora u stalnom sastavu, najdocnije u roku od 24 časa od časa prijema obaveštenja, rešenjem utvrđuju imena lica koja postaju njihovi članovi.</p>
            <p>Ako podnosilac izborne liste ne odredi predstavnika u organ za sprovođenje izbora najdocnije pet dana pre dana određenog za održavanje izbora, taj organ nastavlja da radi i da punovažno odlučuje bez predstavnika podnosioca izborne liste.</p>
            <h2>VI IZBORNA LISTA</h2>
            <h3>Kandidovanje</h3>
            <h3>Član 40</h3>
            <p>Kandidate mogu, pod uslovima utvrđenim ovim zakonom, predlagati registrovane političke stranke i stranačke koalicije (u daljem tekstu: političke stranke), kao i grupe građana.</p>
            <p>U ime političke stranke, odnosno grupe građana, predlog iz stava 1. ovog člana može podneti samo lice koje je politička stranka, odnosno grupa građana ovlastila.</p>
            <p>U ime koalicije stranaka, predlog iz stava 1. ovog člana podnose najviše dva ovlašćena lica.</p>
            <h3>Član 40a</h3>
            <p>Na izbornoj listi mora biti 40% manje zastupljenog pola. Među svakih pet kandidata po redosledu na listi (prvih pet mesta, drugih pet mesta i tako do kraja liste) mora biti najmanje po dva kandidata - pripadnika onog pola koji je manje zastupljen na listi.</p>
            <p>Ako izborna lista ne ispunjava uslove iz stava 1. ovog člana smatraće se da sadrži nedostatke za proglašenje izborne liste, a predlagač liste biće pozvan da otkloni nedostatke liste, u skladu sa ovim zakonom.</p>
            <p>Ako predlagač liste ne otkloni nedostatke iz stava 2. ovog člana, Republička izborna komisija odbiće proglašenje izborne liste u skladu sa ovim zakonom.</p>
            <h3>Član 41</h3>
            <p>Podnosilac izborne liste može povući listu najdocnije do dana utvrđivanja zbirne izborne liste.</p>
            <p>Povlačenjem izborne liste prestaje funkcija predstavnika podnosioca liste u svim organima za sprovođenje izbora, kao i sva prava koja mu u tom svojstvu po odredbama ovog zakona pripadaju.</p>
            <h3>Naziv, utvrđivanje i proglašenje izborne liste</h3>
            <h3>Član 42</h3>
            <p>Naziv izborne liste određuje se prema nazivu političke stranke koja podnosi listu, a u naziv se može uključiti ime i prezime lica koje politička stranka odredi kao nosioca izborne liste.</p>
            <p>Ako dve ili više političkih stranaka podnesu zajedničku izbornu listu, naziv izborne liste i najviše dva nosioca izborne liste određuju se sporazumno.</p>
            <p>Uz naziv izborne liste grupe građana podnosilac određuje bližu oznaku te liste, a u nazivu se može uključiti ime i prezime lica koje grupa građana odredi kao nosioca izborne liste.</p>
            <p>Lice određeno kao nosilac izborne liste može biti kandidat za narodnog poslanika.</p>
            <p>Lice određeno kao nosilac izborne liste može biti kandidat za drugi državni organ za koji se istovremeno sprovode izbori.</p>
            <h3>Član 43</h3>
            <p>Izborna lista je utvrđena kad je svojim potpisima podrži najmanje 10.000 birača.</p>
            <p>Republička izborna komisija propisuje oblik i sadržaj obrasca za potpis iz stava 1. ovog člana i stavlja ga na raspolaganje učesnicima u izborima u roku od pet dana od raspisivanja izbora.</p>
            <p>Birač može svojim potpisom podržati izbornu listu samo jednog predlagača. Svaki potpis iz stava 1. ovog člana mora biti overen u nadležnom sudu.</p>
            <p>Visinu naknade za overu potpisa utvrdiće Ministarstvo pravde Republike Srbije posebnim aktom.</p>
            <h3>Član 44</h3>
            <p>Izborna lista dostavlja se Republičkoj izbornoj komisiji najdocnije 15 dana pre dana određenog za održavanje izbora.</p>
            <p>Uz izbornu listu, Republičkoj izbornoj komisiji dostavlja se dokumentacija, i to:</p>
            <ol>
              <li>
                  <p>potvrda o izbornom pravu za svakog kandidata sa izborne liste, u kojoj je naznačeno ime i prezime, datum rođenja, zanimanje i lični broj kandidata,</p>
              </li>
              <li>
                  <p>pismena izjava kandidata da prihvata kandidaturu,</p>
              </li>
              <li>
                  <p>potvrda o prebivalištu kandidata,</p>
              </li>
              <li>
                  <p>pismena saglasnost nosioca liste,</p>
              </li>
              <li>
                  <p>ovlašćenje lica koja podnose izbornu listu,</p>
              </li>
              <li>
                  <p>uverenje o državljanstvu,</p>
              </li>
              <li>
                  <p>sudski overeni, na posebnom obrascu, potpisi birača koji su podržali određenu izbornu listu.</p>
              </li>
            </ol>
            <h3>Član 45</h3>
            <p>Republička izborna komisija proglašava izbornu listu jedne političke stranke (stranačka izborna lista), listu dve ili više političkih stranaka (koaliciona izborna lista), odnosno grupe građana (izborna lista grupe građana), odmah po prijemu izborne liste i odgovarajuće dokumentacije, a najkasnije u roku od 24 časa od prijema izborne liste.</p>
            <p>Rešenje o proglašenju izborne liste iz stava 1. ovog člana Republička izborna komisija dostavlja bez odlaganja podnosiocu.</p>
            <h3>Član 46</h3>
            <p>Ako Republička izborna komisija utvrdi da izborna lista nije podneta blagovremeno, doneće rešenje o njenom odbacivanju.</p>
            <p>Ako Republička izborna komisija utvrdi da izborna lista sadrži nedostatke koji su smetnja za proglašenje izborne liste u skladu sa ovim zakonom, doneće, u roku od 24 časa od prijema izborne liste, zaključak kojim se podnosiocu izborne liste nalaže da najdocnije u roku od 48 časova od časa dostavljanja zaključka otkloni te nedostatke. Tim zaključkom istovremeno će se podnosiocu izborne liste ukazati na radnje koje treba da obavi radi otklanjanja nedostataka.</p>
            <p>Ako Republička izborna komisija utvrdi da izborna lista sadrži nedostatke predviđene ovim zakonom, odnosno ako utvrdi da nedostaci izborne liste nisu otklonjeni, ili nisu otklonjeni u predviđenom roku, doneće u narednih 48 časova rešenje kojim se odbija proglašenje izborne liste.</p>
            <h3>Zbirna izborna lista</h3>
            <h3>Član 47</h3>
            <p>Zbirnu izbornu listu utvrđuje Republička izborna komisija i ona sadrži sve izborne liste, sa ličnim imenima svih kandidata i podacima o godini rođenja, zanimanju i prebivalištu.</p>
            <p>Redosled izbornih lista, sa imenima svih kandidata, na zbirnoj izbornoj listi utvrđuje se prema redosledu njihovog proglašavanja.</p>
            <p>Zbirnu izbornu listu Republička izborna komisija objavljuje u "Službenom glasniku Republike Srbije", najdocnije 10 dana pre dana određenog za održavanje izbora. Za dan proglašenja zbirne izborne liste uzima se dan njenog objavljivanja u "Službenom glasniku Republike Srbije".</p>
            <p>Svaki podnosilac izborne liste ima pravo da u roku od 48 časova od dana objavljivanja zbirne izborne liste izvrši, preko lica koje ovlasti, uvid u sve podnete izborne liste i dokumentaciju podnetu uz njih.</p>
            <h2>VII PREDSTAVLJANJE PODNOSILACA IZBORNIH LISTA I KANDIDATA SA IZBORNIH LISTA</h2>
            <h3>Član 48</h3>
            <p>Podnosioci izbornih lista imaju pravo da u javnim glasilima obaveštavaju građane o programima i aktivnostima, kao i o predloženim kandidatima, u skladu sa odredbama ovog zakona.</p>
            <h3>Član 49</h3>
            <p>Organizacije koje emituju radio i televizijski program, čiji je osnivač Republika Srbija, obavezne su da od dana raspisivanja izbora, u okviru političko-informativnog programa čija su čujnost i vidljivost obezbeđene na celoj teritoriji Republike, obezbede predstavljanje podnosilaca izbornih lista i kandidata sa izbornih lista, kao i iznošenje i obrazlaganje izbornog programa podnosilaca tih lista, u skladu sa ovim zakonom.</p>
            <p>Organizacijama iz stava 1. ovog člana nije dozvoljeno da, pod bilo kojim uslovima, omoguće predstavljanje kandidata i iznošenje i obrazlaganje programa podnosilaca izbornih lista u komercijalnom, zabavnom ili drugom programu.</p>
            <h3>Član 50</h3>
            <p>Urednici i voditelji političko-informativnih i specijalizovanih emisija organizacija iz člana 49. ovog zakona obavezni su da u toku izborne kampanje nezavisno i objektivno predstavljaju sve kandidate, a voditelji emisija moraju imati nepristrasan odnos prema svim zastupljenim političkim, socijalnim i etničko-kulturnim programima političkih stranaka čiji se kandidati predstavljaju.</p>
            <p>U skladu sa odredbom stava 1. ovog člana a na osnovu člana 5. stav 2. ovog zakona, organizuju se emisije kojima se obezbeđuje javno sučeljavanje izbornih programa podnosilaca izbornih lista i kandidata sa tih lista.</p>
            <h3>Član 51</h3>
            <p>Po dva predstavnika Javnog preduzeća koje emituje radio i televizijski program a čiji je osnivač Republika Srbija, Vlade Republike Srbije i političkih stranaka koje imaju poslanike u Narodnoj skupštini Republike Srbije, sporazumom utvrđuju broj i trajanje emisija za predstavljanje političkih stranaka, političkih organizacija odnosno grupa građana koje nameravaju da učestvuju na izborima.</p>
            <p>Sporazum iz stava 1. ovog člana zaključuje se najdocnije pet dana od dana donošenja odluke o raspisivanju izbora i bez odlaganja javno objavljuje.</p>
            <p>Sredstva javnog obaveštavanja iz stava 1. ovog člana, uz učešće predstavnika osnivača i podnosilaca izbornih lista, utvrđuju bliža pravila za predstavljanje podnosilaca izbornih lista, izbornih programa i kandidata sa izbornih lista.</p>
            <h2>VIII SPROVOĐENJE IZBORA</h2>
            <h3>Biračka mesta</h3>
            <h3>Član 52</h3>
            <p>Glasanje za poslanike obavlja se na biračkom mestu. Biračko mesto određuje se za glasanje najviše 2.500, a najmanje 100 birača. Bliža pravila u vezi sa biračkim mestom utvrđuje Republička izborna komisija.</p>
            <h3>Član 53</h3>
            <p>Birač glasa na biračkom mestu na kome je upisan u izvod iz biračkog spiska.</p>
            <p>Izuzetno, birač može da glasa i izvan biračkog mesta na kome je upisan u izvod iz biračkog spiska, pod uslovima i u postupku određenim ovim zakonom.</p>
            <p>Način glasanja izvan biračkog mesta i broj birača koji su tako glasali, unosi se u zapisnik o radu biračkog odbora.</p>
            <h3>Član 54</h3>
            <p>Svakom biraču obavezno se, najdocnije pet dana pre dana održavanja izbora, dostavlja obaveštenje o danu i vremenu održavanja izbora, sa brojem i adresom biračkog mesta na kome glasa i brojem pod kojim je upisan u izvod iz biračkog spiska.</p>
            <p>Obaveštenje iz stava 1. ovog člana biračima dostavlja organ nadležan za vođenje biračkog spiska.</p>
            <h3>Član 55</h3>
            <p>Svaki birač glasa lično.</p>
            <p>Birač u toku održavanja izbora može glasati samo jedanput. Glasanje je tajno.</p>
            <p>Glasa se na overenim glasačkim listićima.</p>
            <p>Na biračkom mestu i na 50 metara od biračkog mesta zabranjeno je isticanje simbola političkih stranaka i drugog propagandnog materijala.</p>
            <p>Ako se u toku glasanja naruše pravila iz st. 1. do 4. ovog člana, birački odbor se raspušta, a glasanje na tom biračkom mestu se ponavlja.</p>
            <p>Na biračkom mestu zabranjeno je korišćenje pejdžera, mobilnih telefona i drugih sredstava veza i komunikacija.</p>
            <p>Bliža uputstva o merama kojima se obezbeđuje tajnost glasanja utvrđuje Republička izborna komisija.</p>
            <h3>Član 56</h3>
            <p>Biračka mesta se otvaraju u 7,00 časova, a zatvaraju u 20,00 časova. U toku tog vremena biračko mesto mora biti neprekidno otvoreno.</p>
            <p>Biračima koji su se zatekli na biračkom mestu u trenutku njegovog zatvaranja omogućiće se da glasaju.</p>
            <p>Glasanje u inostranstvu traje dva dana i završava se istog dana i časa po lokalnom vremenu koje odgovara završetku glasanja u Republici Srbiji.</p>
            <h3>Član 57</h3>
            <p>Ako se na biračkom mestu naruši red, birački odbor može prekinuti glasanje dok se red ne uspostavi. Razlozi i trajanje prekida glasanja unose se u zapisnik o radu biračkog odbora.</p>
            <p>Ako je glasanje prekinuto duže od jednog časa, produžava se za onoliko vremena koliko je prekid trajao.</p>
            <h3>Član 58</h3>
            <p>Dok je biračko mesto otvoreno i dok traje glasanje svi članovi biračkog odbora ili njihovi zamenici moraju biti na biračkom mestu.</p>
            <p>Za svako biračko mesto obezbediće se posebna prostorija u kojoj je moguće obezbediti tajnost glasanja.</p>
            <p>U prostoriji u kojoj se obavlja glasanje može biti prisutan samo onoliki broj birača koliko ima obezbeđenih mesta za tajnost glasanja.</p>
            <p>Zabranjeno je zadržavanje na biračkom mestu svih lica koja nemaju prava i dužnosti u vezi sa sprovođenjem izbora koja su utvrđena ovim zakonom.</p>
            <p>Pripadnici policije na dužnosti mogu ući na biračko mesto samo ako su na biračkom mestu narušeni red i mir, na poziv predsednika biračkog odbora.</p>
            <p>Zbog povrede odredaba st. 1. do 5. ovog člana može se podneti prigovor Republičkoj izbornoj komisiji, koja odlučuje da li će se glasanje na tom biračkom mestu ponoviti.</p>
            <h3>Izborni materijal</h3>
            <h3>Član 59</h3>
            <p>Glasački listić sadrži:</p>
            <ol>
              <li>
                  <p>redni broj koji se stavlja ispred naziva izborne liste,</p>
              </li>
              <li>
                  <p>nazive izbornih lista, prema redosledu utvrđenom na zbirnoj listi, sa ličnim imenom prvog kandidata sa liste,</p>
              </li>
              <li>
                  <p>napomenu da se glasa samo za jednu izbornu listu, zaokruživanjem rednog broja ispred naziva te liste.</p>
              </li>
            </ol>
            <h3>Član 60</h3>
            <p>Glasačke listiće priprema i overava Republička izborna komisija.</p>
            <p>Republička izborna komisija utvrđuje broj glasačkih listića, koji mora biti jednak broju birača upisanih u birački spisak.</p>
            <p>Republička izborna komisija kontroliše pripremu i overu glasačkih listića i određuje broj rezervnih glasačkih listića.</p>
            <p>Glasački listići štampaju se na jednom mestu, na hartiji zaštićenoj vodenim žigom.</p>
            <p>Podnosilac izborne liste dostavlja Republičkoj izbornoj komisiji ime lica koje ima pravo da prisustvuje štampanju, brojanju, pakovanju glasačkih listića i njihovom dostavljanju organima nadležnim za sprovođenje izbora.</p>
            <p>U opštinama gde su u službenoj upotrebi jezici nacionalnih manjina glasački listići se štampaju i na tim jezicima.</p>
            <p>Republička izborna komisija bliže propisuje oblik i izgled glasačkih listića, način i kontrolu njihovog štampanja i dostavljanje i rukovanje glasačkim listićima.</p>
            <h3>Član 61</h3>
            <p>Ministar nadležan za poslove uprave bliže će propisati oblik i dimenzije glasačke kutije. Glasačke kutije moraju da budu od providnog materijala (pleksiglas, plastika ili slični materijal).</p>
            <h3>Član 62</h3>
            <p>Republička izborna komisija je dužna da za svaki birački odbor blagovremeno pripremi materijal za glasanje, a naročito potreban broj glasačkih listića, zbirnu izbornu listu, izvod iz biračkog spiska, potvrde o izbornom pravu kao i obrazac zapisnika o radu biračkog odbora.</p>
            <p>Primopredaja izbornog materijala vrši se najdocnije 48 časova pre dana održavanja izbora.</p>
            <p>Opštinska uprava stara se o uređivanju biračkih mesta i priprema za svaki birački odbor potreban broj glasačkih kutija sa priborom za njihovo pečaćenje i priborom za pisanje.</p>
            <p>Na dan izbora, pre početka glasanja, birački odbor utvrđuje da li je pripremljeni izborni materijal za to biračko mesto potpun i ispravan, da li je biračko mesto uređeno na način kojim se obezbeđuje tajnost glasanja i da li glasanje može početi, što unosi u zapisnik o svom radu.</p>
            <h3>Član 63</h3>
            <p>Zbirna izborna lista, s nazivima izbornih lista i imenima svih kandidata, mora za vreme glasanja biti vidno istaknuta na biračkom mestu.</p>
            <p>Sadržaj, oblik i način isticanja zbirne izborne liste iz stava 1. ovog člana propisuje Republička izborna komisija.</p>
            <h3>Član 64</h3>
            <p>Predstavnici podnosilaca izbornih lista i kandidati za poslanike imaju pravo uvida u izborni materijal, a naročito u izvode iz biračkih spiskova, zapisnike biračkog odbora, zapisnike Republičke izborne komisije i glasačke listiće. Uvid se vrši u službenim prostorijama Republičke izborne komisije, kao i kod organa kod kojih se izborni materijal nalazi. Uvid u izborni materijal može se izvršiti u roku od pet dana od dana održavanja izbora.</p>
            <h3>Član 65</h3>
            <p>Izborni materijal čuva se najmanje četiri godine.</p>
            <p>Republička izborna komisija propisuje način korišćenja izbornog materijala.</p>
            <h3>Član 66</h3>
            <p>Republička izborna komisija propisuje sadržinu i oblik obrazaca i izbornog materijala potrebnih za sprovođenje izbora u roku od 15 dana od dana njenog imenovanja.</p>
            <h3>Glasanje</h3>
            <h3>Član 67</h3>
            <p>Birački odbor proverava glasačku kutiju u prisustvu birača koji prvi dođe na biračko mesto. Rezultat kontrole upisuje se u kontrolni list, koji potpisuju članovi biračkog odbora i birač koji je prvi došao na biračko mesto.</p>
            <p>U glasačku kutiju ubacuje se kontrolni list, a zatim se ona u prisustvu prvog birača pečati, što se unosi u zapisnik o radu biračkog odbora.</p>
            <p>Po otvaranju glasačke kutije, najpre se proverava da li u njoj postoji kontrolni list. Ako u glasačkoj kutiji nema kontrolnog lista, birački odbor se raspušta i imenuje se novi, a glasanje na tom biračkom mestu se ponavlja.</p>
            <p>Obrazac kontrolnog lista i način pečaćenja glasačke kutije propisuje Republička izborna komisija.</p>
            <h3>Član 68</h3>
            <p>Birač najpre saopštava biračkom odboru svoje lično ime i predaje obaveštenje o glasanju, a ličnom kartom ili drugom ispravom dokazuje svoj identitet.</p>
            <p>Birač ne može glasati bez podnošenja dokaza o svom identitetu. Predsednik ili član biračkog odbora, pošto utvrdi identitet birača, zaokružuje redni broj pod kojim je birač upisan u izvod iz biračkog spiska i objašnjava mu način glasanja.</p>
            <p>Birač potpisuje birački spisak i preuzima glasački listić.</p>
            <p>Svakom biraču koji je pristupio glasanju i primio glasački materijal, specijalnim sprejom će biti obeležen kažiprst desne ruke, kao znak da je već glasao. Trajanje oznake na kažiprstu je minimum 24 časa.</p>
            <h3>Član 69</h3>
            <p>Članovi biračkog odbora ne smeju ni na koji način uticati na odluku birača.</p>
            <p>Članovi biračkog odbora dužni su da biraču, na njegov zahtev, ponovno objasne način glasanja.</p>
            <p>Članovi biračkog odbora dužni su, naročito da paze da niko ne ometa birača prilikom popunjavanja glasačkog listića i da u potpunosti bude obezbeđena tajnost glasanja.</p>
            <p>Ako se u toku glasanja povrede odredbe iz st. 1. do 3. ovog člana Republička izborna komisija raspušta birački odbor, obrazuje novi birački odbor i određuje ponavljanje glasanja u tom biračkom odboru.</p>
            <h3>Član 70</h3>
            <p>Birač može glasati samo za jednu izbornu listu sa glasačkog listića. Glasa se zaokruženjem rednog broja ispred naziva izborne liste za koju se glasa.</p>
            <p>Birač sam presavija popunjeni glasački listić tako da se ne vidi kako je glasao i stavlja ga u odgovarajuću glasačku kutiju, a zatim napušta biračko mesto.</p>
            <h3>Član 71</h3>
            <p>Na dan izbora ne mogu se vršiti nikakve promene u izvodima iz biračkih spiskova.</p>
            <p>Ako postupi protivno odredbi stava 1. ovog člana, birački odbor se raspušta, a glasanje na tom biračkom mestu se ponavlja.</p>
            <h3>Član 72</h3>
            <p>Birač koji nije u mogućnosti da na biračkom mestu lično glasa (slepo, invalidno ili nepismeno lice) ima pravo da povede lice koje će umesto njega, na način koje mu ono odredi, ispuniti listić, odnosno obaviti glasanje.</p>
            <p>Način glasanja za birača iz stava 1. ovog člana unosi se u zapisnik.</p>
            <h3>Član 72a</h3>
            <p>Birač koji nije u mogućnosti da glasa na biračkom mestu (nemoćno ili sprečeno lice) može, najkasnije do 11 časova na dan glasanja, obavestiti birački odbor o tome da želi da glasa.</p>
            <p>Tri člana biračkog odbora u proširenom sastavu biračkog odbora koji su predstavnici podnosilaca tri različite izborne liste koje odredi birački odbor odlaze kod takvog birača, utvrđuju njegov identitet i predaju mu službenu kovertu, overeni glasački listić, zbirnu izbornu listu, potvrdu o biračkom pravu i poseban koverat za popunjeni glasački listić, upoznaju ga sa načinom glasanja, pa napuštaju prostoriju u kojoj birač glasa.</p>
            <p>Pošto glasa, birač potpisuje potvrdu o biračkom pravu, presavija glasački listić i stavlja ga u poseban koverat koji prisutni članovi biračkog odbora pred njim pečate žigom na pečatnom vosku, posle čega stavljaju u službeni koverat potpisanu potvrdu o biračkom pravu i poseban koverat u kome se nalazi glasački listić.</p>
            <p>Po povratku na biračko mesto, članovi biračkog odbora odmah predaju ostalim članovima biračkog odbora službeni koverat, koji birački odbor otvara, na osnovu potpisane potvrde o biračkom pravu zaokružuje redni broj pod kojim je birač upisan u izvodu iz biračkog spiska i na kraju otvara zapečaćeni koverat iz koga vadi presavijeni glasački listić i tako ga ubacuje u glasačku kutiju da se ne vidi za koga je birač glasao.</p>
            <p>Ako u službenom kovertu nema potpisane potvrde o biračkom pravu, smatra se da birač nije glasao.</p>
            <h3>Član 72b</h3>
            <p>Birač koji se na dan glasanja nalazi u pritvoru ili izdržava zavodsku sankciju, glasa na biračkom mestu unutar zavoda u kome se nalazi.</p>
            <p>Republička izborna komisija određuje posebna biračka mesta unutar zavoda i imenuje članove biračkog odbora u stalnom i proširenom sastavu. Lica koja rade u ministarstvu nadležnom za poslove pravosuđa i lica koja glasaju u zavodu ne mogu biti u sastavu biračkog odbora.</p>
            <p>Ministarstvo nadležno za poslove uprave priprema i overava posebne izvode iz biračkog spiska za svaki zavod i dostavlja ih Republičkoj izbornoj komisiji u roku od 24 časa od dana donošenja rešenja o zaključenju biračkog spiska.</p>
            <p>Posebne izvode iz stava 3. ovog člana Republička izborna komisija dostavlja biračkim odborima u zavodima.</p>
            <h3>Član 73</h3>
            <p>Birači koji su na odsluženju vojnog roka, na vojnoj vežbi ili na školovanju u jedinicama ili ustanovama Vojske Srbije glasaju na objavljenom biračkom mestu koje je najbliže jedinici ili ustanovi u kojoj služe vojni rok ili se nalaze na vojnoj vežbi, odnosno na školovanju.</p>
            <p>Republička izborna komisija, najkasnije 20 dana pre dana održavanja izbora, u "Službenom glasniku Republike Srbije" objavljuje na kojim će, već određenim, biračkim mestima glasati vojnici koji su na odsluženju vojnog roka, na vojnoj vežbi ili na školovanju u jedinicama ili ustanovama Vojske Srbije.</p>
            <p>Ministarstvo nadležno za poslove uprave priprema i overava posebne izvode iz biračkog spiska za glasanje birača iz stava 1. ovog člana i dostavlja ih Republičkoj izbornoj komisiji u roku od 24 časa od dana donošenja rešenja o zaključenju biračkog spiska.</p>
            <p>Posebne izvode iz stava 3. ovog člana Republička izborna komisija dostavlja biračkim odborima na biračkim mestima na kojima glasaju birači koji su na odsluženju vojnog roka, na vojnoj vežbi ili na školovanju u jedinicama ili ustanovama Vojske Srbije.</p>
            <h3>Član 73a</h3>
            <p>Birači koji imaju boravište u inostranstvu glasaju u diplomatsko-konzularnim predstavništvima Republike Srbije i na posebnim biračkim mestima koja određuje Republička izborna komisija, po pribavljenom mišljenju ministarstva nadležnog za inostrane poslove.</p>
            <p>Ministarstvo nadležno za poslove uprave priprema i overava posebne izvode iz biračkog spiska za glasanje birača iz stava 1. ovog člana i dostavlja ih Republičkoj izbornoj komisiji u roku od 24 časa od dana donošenja rešenja o zaključenju biračkog spiska.</p>
            <p>Posebne izvode iz stava 2. ovog člana Republička izborna komisija dostavlja ministarstvu nadležnom za inostrane poslove koje ih, zajedno sa ostalim izbornim materijalom, prosleđuje na sva biračka mesta u inostranstvu.</p>
            <p>Članove biračkog odbora u stalnom sastavu u inostranstvu imenuje Republička izborna komisija, na predlog ministarstva nadležnog za inostrane poslove.</p>
            <p>Birački odbor u proširenom sastavu u inostranstvu čini po jedan predstavnik podnosioca izborne liste čija je lista proglašena. Članove biračkog odbora u proširenom sastavu u inostranstvu imenuje Republička izborna komisija, na predlog podnosioca izborne liste čija je lista proglašena.</p>
            <p>Predsednik biračkog odbora imenuje se, po pravilu, iz reda diplomiranih pravnika zaposlenih u diplomatsko-konzularnom predstavništvu.</p>
            <h3>Čl. 73b-73g<strong>**</strong></h3>
            <p>(Prestali da važe)</p>
            <h2>IX UTVRĐIVANJE I OBJAVLJIVANJE REZULTATA IZBORA</h2>
            <h3>Utvrđivanje rezultata izbora</h3>
            <h3>Član 74</h3>
            <p>Po završenom glasanju birački odbor pristupa utvrđivanju rezultata glasanja na biračkom mestu.</p>
            <p>Birački odbor utvrđuje broj neupotrebljenih glasačkih listića i stavlja ih u poseban omot koji pečati.</p>
            <p>Na osnovu izvoda iz biračkog spiska, birački odbor utvrđuje ukupan broj birača koji su glasali.</p>
            <p>Kad se glasačka kutija otvori, posle provere kontrolnog lista, važeći glasački listići odvajaju se od nevažećih.</p>
            <p>Birački odbor konstatuje broj nevažećih glasačkih listića i unosi ga u zapisnik, a potom utvrđuje broj važećih listića i broj glasova za svaku izbornu listu, što takođe unosi u zapisnik.</p>
            <p>Nevažeći glasački listić jeste nepopunjeni glasački listić, listić koji je popunjen tako da se ne može utvrditi za koju se izbornu listu glasalo i listić na kome je zaokruženo više od jedne izborne liste.</p>
            <p>Ako je na glasačkom listiću zaokruženo ime i prezime prvog kandidata na izbornoj listi ili je zaokružen naziv ili deo naziva izborne liste, odnosno ako su istovremeno zaokruženi redni broj i naziv izborne liste i ime i prezime prvog kandidata, takav izborni listić smatra se važećim.</p>
            <p>Ako se utvrdi da je broj glasačkih listića u glasačkoj kutiji veći od broja birača koji su glasali, birački odbor se raspušta i imenuje novi, a glasanje na tom biračkom mestu ponavlja se.</p>
            <h3>Član 75</h3>
            <p>Birački odbor po utvrđivanju rezultata glasanja u zapisnik o svom radu unosi broj primljenih glasačkih listića; broj neupotrebljenih glasačkih listića; broj nevažećnh glasačkih listića; broj važećih glasačkih listića; broj glasova datih za svaku izbornu listu; broj birača prema izvodu iz biračkog spiska i broj birača koji su glasali.</p>
            <p>U zapisnik o radu biračkog odbora unose se i primedbe i mišljenja članova biračkog odbora, podnosilaca izbornih lista i zajedničkih predstavnika podnosilaca izbornih lista, kao i sve druge činjenice od značaja za glasanje.</p>
            <p>Zapisnik o radu biračkog odbora potpisuju svi članovi biračkog odbora.</p>
            <h3>Član 76</h3>
            <p>Zapisnik o radu biračkog odbora izrađuje se na propisanom obrascu koji se štampa u šest primeraka.</p>
            <p>U opštinama gde su u službenoj upotrebi jezici nacionalnih manjina zapisnik o radu biračkog odbora štampa se i na tim jezicima.</p>
            <p>Prvi primerak zapisnika sa utvrđenim izbornim materijalom dostavlja se Republičkoj izbornoj komisiji.</p>
            <p>Drugi primerak zapisnika ističe se na biračkom mestu na javni uvid.</p>
            <p>Preostala četiri primerka zapisnika uručuju se predstavnicima podnosilaca izbornih lista koje su osvojile najveći broj glasova na tom biračkom mestu i to odmah ukoliko podnosilac izborne liste ima predstavnika u biračkom odboru, a ukoliko ga nema predstavnik podnosioca izborne liste može preuzeti primerak zapisnika od izborne komisije u roku od 12 časova.</p>
            <p>Ostali podnosioci izborne liste imaju pravo da, u roku od 12 časova od dostavljanja materijala sa biračkog mesta Republičkoj izbornoj komisiji, od Republičke izborne komisije dobiju overenu fotokopiju zapisnika sa biračkog mesta.</p>
            <h3>Član 77</h3>
            <p>Po utvrđivanju rezultata glasanja birački odbor će bez odlaganja, a najdocnije u roku od 18 časova od časa zatvaranja biračkog mesta, dostaviti Republičkoj izbornoj komisiji: zapisnik o radu, izvod iz biračkog spiska; neupotrebljene i, odvojeno, upotrebljene glasačke listiće; nevažeće i, odvojeno, važeće glasačke listiće, kao i preostali izborni materijal.</p>
            <h3>Član 78</h3>
            <p>U roku od 96 časova od zatvaranja biračkih mesta, Republička izborna komisija zapisnički utvrđuje: ukupan broj birača upisanih u birački spisak; broj birača koji je glasao na biračkim mestima; ukupan broj glasačkih listića primljenih na biračkim mestima; ukupan broj neupotrebljenih glasačkih listića; ukupan broj nevažećih glasačkih listića; ukupan broj važećih glasačkih listića; broj glasova datih za svaku izbornu listu pojedinačno.</p>
            <p>Republička izborna komisija utvrđuje rezultate izbora i o tome sačinjava poseban zapisnik.</p>
            <p>Republička izborna komisija propisuje sadržaj i oblik obrasca zapisnika o svom radu.</p>
            <h3>Član 79</h3>
            <p>Podnosioci izbornih lista mogu Republičkoj izbornoj komisiji prijaviti lice koje će imati pravo da prisustvuje statističkoj obradi podataka u Republičkoj izbornoj komisiji.</p>
            <h3>Član 80</h3>
            <p>Svakoj izbornoj listi pripada broj mandata koji je srazmeran broju dobijenih glasova.</p>
            <h3>Član 81</h3>
            <p>U raspodeli mandata učestvuju samo izborne liste koje su dobile najmanje 3% glasova od ukupnog broja glasova birača koji su glasali u izbornoj jedinici.</p>
            <p>Političke stranke nacionalnih manjina i koalicije političkih stranaka nacionalnih manjina učestvuju u raspodeli mandata i kad su dobile manje od 3% glasova od ukupnog broja birača koji su glasali, pri čemu se prilikom raspodele mandata primenom sistema najvećeg količnika, količnici svih izbornih lista političkih stranaka nacionalnih manjina i koalicija političkih stranaka nacionalnih manjina uvećavaju za 35%.</p>
            <p>Politička stranka nacionalne manjine, u smislu stava 2. ovog člana, jeste ona stranka za koju je Republička izborna komisija utvrdila da joj je osnovni cilj predstavljanje i zastupanje interesa nacionalne manjine i zaštita i poboljšanje prava pripadnika nacionalne manjine, u skladu s međunarodno pravnim standardima.</p>
            <p>O tome da li podnosilac izborne liste ima položaj političke stranke nacionalne manjine ili koalicije političkih stranaka nacionalnih manjina, u smislu stava 2. ovog člana, odlučuje Republička izborna komisija posebnim rešenjem pri proglašenju izborne liste, a na predlog podnosioca izborne liste koji mora biti dostavljen pri podnošenju izborne liste. Republička izborna komisija može zatražiti mišljenje nadležnog nacionalnog saveta nacionalne manjine o tome da li je podnosilac izborne liste politička stranka nacionalne manjine ili koalicija političkih stranaka nacionalnih manjina.</p>
            <h3>Član 82</h3>
            <p>Republička izborna komisija raspodeljuje mandate primenom sistema najvećeg količnika.</p>
            <p>Mandati se raspodeljuju tako što se ukupan broj glasova koji je dobila svaka pojedina izborna lista podeli brojevima od jedan do zaključno sa brojem 250.</p>
            <p>Dobijeni količnici razvrstavaju se po veličini a u obzir se uzima 250 najvećih količnika. Svaka izborna lista dobija onoliko mandata koliko tih količnika na nju otpada.</p>
            <p>Ako dve izborne liste ili više izbornih lista dobiju iste količnike na osnovu kojih se dodeljuje jedan mandat a nema više neraspodeljenih mandata, mandat će se dodeliti listi koja je dobila ukupan veći broj glasova.</p>
            <p>Ako nijedna izborna lista nije dobila najmanje 3% glasova, raspodela će se izvršiti na način određen u st. 1. do 3. ovog člana.</p>
            <h3>Član 83</h3>
            <p>Mandati koji pripadaju određenoj izbornoj listi dodeljuju se kandidatima sa te liste, u skladu sa odredbama ovog zakona.</p>
            <p>Kad određenoj izbornoj listi pripadne više mandata nego što je na toj listi predloženo kandidata za poslanike, mandat se dodeljuje izbornoj listi koja ima sledeći najveći količnik.</p>
            <h3>Član 84<strong>*</strong></h3>
            <p>Republička izborna komisija će najkasnije u roku od 10 dana od dana objavljivanja ukupnih rezultata izbora sve dobijene mandate sa izborne liste dodeliti kandidatima po redosledu na izbornoj listi, počev od prvog kandidata sa liste.</p>
            <h3>Objavljivanje rezultata izbora</h3>
            <h3>Član 85</h3>
            <p>Republička izborna komisija objavljuje u "Službenom glasniku Republike Srbije" podatke o ukupnim rezultatima izbora poslanika i to o:</p>
            <ol>
              <li>
                  <p>broju birača upisanih u birački spisak,</p>
              </li>
              <li>
                  <p>ukupnom broju birača koji su glasali,</p>
              </li>
              <li>
                  <p>broju primljenih glasačkih listića,</p>
              </li>
              <li>
                  <p>broju neupotrebljenih glasačkih listića,</p>
              </li>
              <li>
                  <p>broju upotrebljenih glasačkih listića,</p>
              </li>
              <li>
                  <p>broju nevažećih glasačkih listića,</p>
              </li>
              <li>
                  <p>broju važećih glasačkih listića,</p>
              </li>
              <li>
                  <p>broju glasova koji su dobile pojedine izborne liste,</p>
              </li>
              <li>
                  <p>broju mandata koji su dobile pojedine izborne liste.</p>
              </li>
            </ol>
            <h3>Član 86</h3>
            <p>Rezultate izbora Republička izborna komisija objavljuje u roku od 96 časova od časa završetka glasanja.</p>
            <p>Od završetka glasanja do objavljivanja rezultata izbora, Republička izborna komisija objavljuje preko sredstava obaveštavanja privremene podatke o rezultatima izbora.</p>
            <p>Rezultati izbora objavljuju se u "Službenom glasniku Republike Srbije".</p>
            <h3>Član 87</h3>
            <p>Republička izborna komisija izdaje poslaniku uverenje da je izabran.</p>
            <h2>X PRESTANAK MANDATA, PONOVNI IZBORI I POPUNJAVANJE UPRAŽNJENIH POSLANIČKIH MESTA</h2>
            <h3>Prestanak mandata</h3>
            <h3>Član 88*</h3>
            <p>Poslaniku prestaje mandat pre isteka vremena na koje je izabran:</p>
            <ol>
              <li>
                  <p>(prestala da važi Odlukom USRS),</p>
              </li>
              <li>
                  <p>podnošenjem ostavke,</p>
              </li>
              <li>
                  <p>ako je pravosnažnom sudskom odlukom osuđen na kaznu zatvora bezuslovno u trajanju od najmanje šest meseci,</p>
              </li>
              <li>
                  <p>ako je pravosnažnom sudskom odlukom lišen poslovne sposobnosti,</p>
              </li>
              <li>
                  <p>preuzimanjem posla ili funkcije koji su, prema ovom zakonu, nespojivi sa funkcijom poslanika,</p>
              </li>
              <li>
                  <p>gubljenjem državljanstva,</p>
              </li>
              <li>
                  <p>ako mu prestane prebivalište na teritoriji Republike Srbije,</p>
              </li>
              <li>
                  <p>ako nastupi smrt poslanika,</p>
              </li>
              <li>
                  <p>(prestala da važi Odlukom USRS)</p>
              </li>
            </ol>
            <p>Poslanik lično podnosi ostavku, overenu kod organa nadležnog za overu potpisa, predsedniku Narodne skupštine, u roku od tri dana od dana overe.</p>
            <p>Poslaniku mandat prestaje danom nastupanja slučaja iz stava 1. ovog člana.</p>
            <p>Dan prestanka mandata konstatuje Narodna skupština Republike Srbije odmah posle prijema obaveštenja o razlozima za prestanak mandata poslanika, na sednici koja je u toku, odnosno na prvoj narednoj sednici.</p>
            <h3>Član 89</h3>
            <p>Ponovni izbori sprovode se ako Republička izborna komisija poništi izbore zbog nepravilnosti u sprovođenju izbora u slučajevima utvrđenim ovim zakonom.</p>
            <h3>Član 90</h3>
            <p>Ako Republička izborna komisija poništi izbore na pojedinom biračkom mestu, glasanje se ponavlja samo na tom biračkom mestu.</p>
            <p>Kad se izbori ponavljaju u slučajevima utvrđenim ovim zakonom Republička izborna komisija donosi rešenje o ponavljanju glasanja.</p>
            <p>U slučaju iz čl. 89. i 90. ovog zakona rezultat izbora se utvrđuje po završetku ponovljenog glasanja.</p>
            <h3>Član 91</h3>
            <p>Ponovni izbori se sprovode na način i po postupku koji su ovim zakonom utvrđeni za sprovođenje izbora.</p>
            <p>Ponovljene izbore raspisuje Republička izborna komisija.</p>
            <p>Ponovni izbori sprovode se najdocnije 15 dana od dana poništenja izbora u Republici Srbiji, odnosno sedam dana od dana poništenja izbora na biračkom mestu.</p>
            <p>Ponovni izbori se sprovode po listi kandidata koja je utvrđena za izbore koji su poništeni, osim kad su izbori poništeni zbog nepravilnosti u utvrđivanju izborne liste.</p>
            <h3>Član 92</h3>
            <p>Ako na osnovu člana 88. stav 1. ovog zakona poslaniku prestane mandat pre isteka vremena na koje je izabran, mandat se dodeljuje novom poslaniku, na način utvrđen ovim članom.</p>
            <p>Kad poslaniku prestane mandat pre isteka vremena na koje je izabran, mandat se dodeljuje prvom sledećem kandidatu sa iste izborne liste kome nije bio dodeljen mandat poslanika.</p>
            <p>Kad poslaniku koji je izabran sa koalicione izborne liste prestane mandat pre isteka vremena na koje je izabran, mandat se dodeljuje prvom sledećem kandidatu na izbornoj listi kome nije bio dodeljen mandat - pripadniku iste političke stranke.</p>
            <p>Kandidatu kome je bio dodeljen mandat poslanika, a kome je mandat prestao zbog preuzimanja funkcije člana Vlade, mandat se ponovo dodeljuje u istom sazivu Narodne skupštine pod uslovima:</p>
            <ul>
              <li>
                  <p>da je kandidatu prestala funkcija člana Vlade,</p>
              </li>
              <li>
                  <p>da postoji upražnjeno poslaničko mesto koje pripada istoj izbornoj listi, i</p>
              </li>
              <li>
                  <p>da je Republičkoj izbornoj komisiji kandidat podneo zahtev za dodelu mandata poslanika.</p>
              </li>
            </ul>
            <p>Kad poslaniku prestane mandat pre isteka vremena na koje je izabran u slučajevima iz stava 1. ovog člana, a na izbornoj listi sa koje je poslanik bio izabran nema kandidata za koje podnosilac izborne liste nije dobio mandat, mandat pripada podnosiocu izborne liste koji ima sledeći najveći količnik a za njega nije dobio mandat.</p>
            <p>Mandat novog poslanika traje do isteka mandata poslanika kome je prestao mandat.</p>
            <p>U slučajevima iz st. 2, 3. i 5. ovog člana od kandidata se pre utvrđivanja mandata pribavlja pismena saglasnost da prihvata mandat.</p>
            <h2>XI ZAŠTITA IZBORNOG PRAVA</h2>
            <h3>Član 93</h3>
            <p>Organi nadležni za sprovođenje izbora dužni su da u toku izbornog postupka obaveštavaju birače o njihovim izbornim pravima i o načinu zaštite tih prava.</p>
            <h3>Član 94</h3>
            <p>Svaki birač, kandidat za poslanika i podnosilac izborne liste ima pravo na zaštitu izbornog prava, po postupku utvrđenom ovim zakonom.</p>
            <h3>Član 95</h3>
            <p>Svaki birač, kandidat i podnosilac izborne liste ima pravo da podnese prigovor Republičkoj izbornoj komisiji zbog povrede izbornog prava u toku izbora ili nepravilnosti u postupku predlaganja odnosno izbora.</p>
            <p>Prigovor protiv odluke, radnje ili propusta biračkog odbora podnosi se Republičkoj izbornoj komisiji.</p>
            <p>Prigovor iz st. 1. i 2. ovog člana podnosi se u roku od 24 časa od časa kad je doneta odluka odnosno izvršena radnja koju podnosilac prigovora smatra nepravilnom, odnosno od časa kad je učinjen propust.</p>
            <h3>Član 96</h3>
            <p>Republička izborna komisija donosi rešenje u roku od 48 časova od časa prijema prigovora i dostavlja ga podnosiocu prigovora i svim podnosiocima izbornih lista.</p>
            <p>Ako Republička izborna komisija usvoji prigovor, poništiće odluku ili radnju.</p>
            <p>Ako Republička izborna komisija po prigovoru ne donese rešenje u rokovima predviđenim ovim zakonom, smatraće se da je prigovor usvojen.</p>
            <h3>Član 97</h3>
            <p>Protiv svakog rešenja Republičke izborne komisije donetog po prigovoru može se izjaviti žalba Upravnom sudu.</p>
            <p>Žalba se podnosi preko Republičke izborne komisije u roku od 48 časova od prijema rešenja.</p>
            <p>Republička izborna komisija dužna je da u roku od 24 časa od prijema žalbe dostavi Upravnom sudu prigovor i sve potrebne spise.</p>
            <p>Upravni sud odlučuje po žalbi shodnom primenom odredaba zakona kojim se uređuje postupak u upravnim sporovima.</p>
            <p>Odluka po žalbi doneće se najkasnije u roku od 48 časova od prijema žalbe sa spisima.</p>
            <p>Odluka doneta u postupku po žalbi je pravnosnažna i protiv nje se ne mogu podneti vanredna pravna sredstva predviđena zakonom kojim se uređuje upravni spor.</p>
            <p>Ako sud usvoji žalbu i poništi izbornu radnju, odnosno izbore, odgovarajuća izborna radnja, odnosno izbori ponoviće se najdocnije za deset dana.</p>
            <h2>XII TROŠKOVI SPROVOĐENJA IZBORA</h2>
            <h3>Član 98</h3>
            <p>Sredstva za rad organa za sprovođenje izbora, izborni materijal i druge troškove sprovođenja izbora obezbeđuju se u budžetu Republike.</p>
            <p>Zahtev za dodelu sredstava sa specifikacijom ukupnih troškova podnosi Republička izborna komisija.</p>
            <p>Sredstva koja se isplaćuju kao naknada za rad lica u organima za sprovođenje izbora oslobođena su plaćanja poreza i doprinosa.</p>
            <h3>Član 99</h3>
            <p>U postupku sprovođenja izbora opšti nadzor nad postupcima političkih stranaka, kandidata i sredstava javnog obaveštavanja u toku izbornih aktivnosti sprovodi nadzorni odbor.</p>
            <p>Nadzorni odbor ima deset članova od kojih polovinu članova imenuje Narodna skupština Republike Srbije na predlog Vlade Republike Srbije, a polovinu na predlog poslaničkih grupa u Narodnoj skupštini Republike Srbije iz reda istaknutih javnih radnika, pod uslovom da oni nisu članovi organa političkih stranaka koje učestvuju na izborima.</p>
            <p>Predsednika nadzornog odbora biraju između sebe članovi nadzornog odbora tajnim glasanjem.</p>
            <h3>Član 100</h3>
            <p>Nadzorni odbor:</p>
            <ol>
              <li>
                  <p>prati predizborne aktivnosti i ukazuje na eventualne nepravilnosti u postupanju političkih stranaka, kandidata i drugih učesnika u izbornom postupku,</p>
              </li>
              <li>
                  <p>kontroliše postupanje sredstava javnog obaveštavanja u primeni odredaba ovog zakona koje se odnose na obezbeđivanje ravnopravnih uslova za predstavljanje podnosilaca izbornih lista i kandidata sa izbornih lista,</p>
              </li>
              <li>
                  <p>predlaže mere za poštovanje jednakosti kandidata u izlaganju njihovih programa,</p>
              </li>
              <li>
                  <p>obraća se javnosti radi zaštite moralnog integriteta ličnosti kandidata,</p>
              </li>
              <li>
                  <p>upozorava na postupke političkih stranaka organa uprave, kandidata i sredstava javnog obaveštavanja kojima se ometa izborna kampanja i ugrožava jednakost prava svih kandidata.</p>
              </li>
            </ol>
            <p>Ako bilo koji učesnik u izbornoj kampanji svojim ponašanjem poziva na nasilje, širi nacionalnu, versku ili rasnu mržnju ili podstiče na neravnopravnost polova, nadzorni odbor za izbornu kampanju bez odlaganja će dati inicijativu za pokretanje postupka pred nadležnim državnim organima.</p>
            <p>Ako se sporazum iz člana 51. ovog zakona ne zaključi u predviđenom roku, nadzorni odbor utvrdiće broj i trajanje emisija za ravnopravno predstavljanje podnosilaca izbornih lista.</p>
            <h3>Član 101</h3>
            <p>Narodna skupština Republike Srbije imenovaće članove Republičke izborne komisije u roku od 20 dana od dana stupanja na snagu ovog zakona.</p>
            <p>Republička izborna komisija doneće akte predviđene ovim zakonom najdocnije u roku od pet dana od dana imenovanja svojih članova.</p>
            <p>Republička izborna komisija određuje biračka mesta na kojima će se obaviti glasanje za narodne poslanike tako da se omogući biračima da glasanje za te poslanike i za druge poslanike, odnosno odbornike, za koje se istog dana sprovode izbori obave u istoj prostoriji ili, po mogućnosti, u najbližoj prostoriji u kojoj se istovremeno sprovode drugi izbori.</p>
            <h3>Član 102</h3>
            <p>Republička organizacija nadležna za poslove statistike objaviće u "Službenom glasniku Republike Srbije" podatke o broju birača u roku od deset dana od dana stupanja na snagu ovog zakona.</p>
            <h3>Član 103**</h3>
            <p>(Prestao da važi)</p>
            <h2>XIII KAZNENE ODREDBE</h2>
            <h3>Član 104</h3>
            <p>Kaznom zatvora do tri godine kazniće se za krivično delo član Republičke izborne komisije ili član biračkog odbora, ili drugo lice koje u vršenju dužnosti u vezi sa izborom poslanika izmeni broj datih glasova dodavanjem ili oduzimanjem glasačkih listića ili glasova pri prebrojavanju ili koje objavi rezultat izbora koji ne odgovara obavljenom glasanju.</p>
            <h3>Član 105<em>*</em>*</h3>
            <p>(Prestao da važi)</p>
            <h3>Član 106</h3>
            <p>Novčanom kaznom ili kaznom zatvora do jedne godine kazniće se za krivično delo:</p>
            <ol>
              <li>
                  <p>ko posle obavljenih izbora za izbor poslanika pozove birača na odgovornost zbog glasanja ili zahteva od njega da kaže kako je glasao ili zašto nije glasao,</p>
              </li>
              <li>
                  <p>ko na izborima za izbor poslanika glasa umesto drugog birača pod njegovim imenom ili na istom glasanju glasa dva ili više puta,</p>
              </li>
              <li>
                  <p>ko na izborima za poslanike uništi, ošteti, oduzme ili prikrije glasački listić kojim je obavljeno glasanje, neku od isprava o izborima ili bilo koji predmet namenjen izborima ili glasanju.</p>
              </li>
            </ol>
            <p>Ako delo iz stava 1. ovog člana učini član Republičke izborne komisije, član biračkog odbora ili drugo lice u vršenju dužnosti u vezi sa navedenim izborima - kazniće se zatvorom od tri meseca do tri godine.</p>
            <h3>Član 107<em>*</em>*</h3>
            <p>(Prestao da važi)</p>
            <h3>Član 108<em>*</em></h3>
            <p>Novčanom kaznom od 100.000 do 600.000 dinara kazniće se za prekršaj organizacija koja objavi procenu rezultata izbora ili prethodne rezultate izbora, suprotno odredbi člana 5. stav 3. ovog zakona.</p>
            <p>Za prekršaj iz stava 1. kazniće se i odgovorno lice u organizaciji novčanom kaznom od 20.000 do 50.000 dinara.</p>
            <p>Za prekršaj iz stava 1. ovog člana kazniće se i lice koje objavi procenu rezultata izbora ili prethodne rezultate izbora novčanom kaznom od 10.000 do 40.000 dinara.</p>
            <h3>Član 109<em>*</em></h3>
            <p>Novčanom kaznom od 25.000 do 50.000 dinara kazniće se za prekršaj član biračkog odbora ili Republičke izborne komisije, koji onemogući praćenje rada organa za sprovođenje izbora (član 32. stav 2. Zakona).</p>
            <h3>Član 110<em>*</em></h3>
            <p>Novčanom kaznom od 50.000 do 800.000 dinara kazniće se za prekršaj organizacije iz člana 51. ovog zakona ako ne izvršavaju obaveze utvrđene odredbama ovog zakona odnosno ako omoguće predstavljanje kandidata suprotno članu 49. stav 2. ovog zakona.</p>
            <p>Za prekršaj iz stava 1. ovog člana kazniće se i odgovorno lice u organizaciji iz člana 51. ovog zakona novčanom kaznom od 10.000 do 30.000 dinara.</p>
            <h3>Član 111<em>*</em></h3>
            <p>Novčanom kaznom od 20.000 do 50.000 dinara kazniće se rukovodilac organa nadležnog za vođenje biračkih spiskova ukoliko ne izvrši obaveze iz čl. 12, 19. i 54. Zakona.</p>
            <h3>Član 112<em>*</em></h3>
            <p>Novčanom kaznom od 100.000 do 400.000 dinara kazniće se za prekršaj politička stranka, koalicija odnosno druga politička organizacija ili drugo pravno lice koje suprotno odredbama ovog zakona ističe simbole političke stranke, odnosno druge političke organizacije, kao i drugi propagandni materijal (član 55. Zakona).</p>
            <p>Za prekršaj iz stava 1. ovog člana kazniće se i odgovorno lice u političkoj organizaciji ili drugom pravnom licu novčanom kaznom od 10.000 do 25.000 dinara.</p>
            <h3>Član 113<em>*</em></h3>
            <p>Novčanom kaznom od 10.000 do 30.000 dinara kazniće se za prekršaj lice koje na biračkom mestu koristi pejdžer, mobilni telefon i druga sredstva veza i komunikacija (član 55. Zakona).</p>
            <h3>Član 114<em>*</em></h3>
            <p>Novčanom kaznom od 10.000 do 30.000 dinara kazniće se lice koje izazove nered na biračkom mestu, usled čega je glasanje moralo biti prekinuto (član 57. Zakona)</p>
            <h2>XIV PRELAZNE I ZAVRŠNE ODREDBE</h2>
            <h3>Član 115</h3>
            <p>Ovaj zakon stupa na snagu osmog dana od dana objavljivanja u "Službenom glasniku Republike Srbije".</p>
            <h3>Član 116</h3>
            <p>Danom stupanja na snagu ovog zakona prestaje da važi Zakon o izboru narodnih poslanika ("Službeni glasnik RS", br. 79/92, 83/92, 53/93, 67/93, 90/93, 107/93, 48/94 i 32/97).</p>
            <h3>Član 117</h3>
            <p>Danom stupanja na snagu ovog zakona prestaje da važi Zakon o izbornim jedinicama za izbor narodnih poslanika ("Službeni glasnik RS", broj 32/97).</p>
            <p>Samostalni članovi Zakona o izmenama i dopunama
              Zakona o izboru narodnih poslanika
            </p>
            <p>("Sl. glasnik RS", br. 18/2004)</p>
            <h3>Član 16</h3>
            <p>Ministarstvo nadležno za unutrašnje poslove dužno je da u roku od 30 dana od stupanja ovog zakona na snagu dostavi ministarstvu nadležnom za poslove uprave spisak birača koji imaju boravište u inostranstvu.</p>
            <p>Ministarstvo nadležno za poslove uprave dužno je da uspostavi Posebnu evidenciju birača u inostranstvu u roku od 30 dana od prijema spiska birača koji imaju boravište u inostranstvu.</p>
            <h3>Član 17</h3>
            <p>Ako izbori za Narodnu skupštinu budu održani pre uspostavljanja Posebne evidencije birača u inostranstvu, ministarstvo nadležno za inostrane poslove, odmah po raspisivanju izbora, putem javnog oglasa obavestiće birače koji imaju boravište u inostranstvu da su raspisani izbori i pozvati ih da diplomatsko-konzularnim predstavništvima državne zajednice Srbija i Crna Gora dostave podatke potrebne za ažuriranje biračkog spiska.</p>
            <p>Dobijene podatke ministarstvo nadležno za inostrane poslove dostaviće Republičkoj izbornoj komisiji najkasnije 15 dana pre dana održavanja izbora. Republička izborna komisija će, na osnovu dostavljenih podatka, sačiniti i overiti posebne izvode iz biračkog spiska za svako biračko mesto u inostranstvu i dostaviti ih ministarstvu nadležnom za inostrane poslove koje će ih, zajedno sa ostalim izbornim materijalom, proslediti na sva biračka mesta u inostranstvu na kojima će se glasati.</p>
            <h3>Član 18</h3>
            <p>Ovaj zakon na snagu stupa narednog dana od dana objavljivanja u "Službenom glasniku Republike Srbije".</p>
            <p>Samostalni član Zakona o izmenama i dopunama
              Zakona o izboru narodnih poslanika
            </p>
            <p>("Sl. glasnik RS", br. 36/2011)</p>
            <h3>Član 17</h3>
            <p>Ovaj zakon stupa na snagu osmog dana od dana objavljivanja u "Službenom glasniku Republike Srbije", a odredbe čl. 13. i 15. ovog zakona primenjuju se od raspisivanja narednih izbora za narodne poslanike Narodne skupštine Republike Srbije.</p>
            <p>Samostalni član Zakona o izmenama
              Zakona o izboru narodnih poslanika
            </p>
            <p>("Sl. glasnik RS", br. 12/2020)</p>
            <h3>Član 3</h3>
            <p>Ovaj zakon stupa na snagu narednog dana od dana objavljivanja u "Službenom glasniku Republike Srbije".</p>
          
        </mat-expansion-panel>
      </mat-accordion>

    </mat-card-content>
  </mat-card>


  </div>
