import { Component, OnInit, NgZone, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from './../../services/auth.service';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public availableLanguages = environment.availableLanguages;

  mobileMenuOpen = false;
  logedIn: boolean = false;
  electionYear = '2020';
  filterNavigationEvent: any;
  irregularitiesActive: boolean = false;

  constructor(
    private translateService: TranslateService,
    public angularFireAuthGuard: AngularFireAuthGuard,
    public authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.logedIn = this.authService.loggedIn;
    this.authService.loggedInChange.subscribe((resp) => this.logedIn = resp);
    this.setElectionYear();
    this.checkIfIrregularitiesActive();
    this.onRouteChange();
  }

  onRouteChange() {
    this.filterNavigationEvent = this.router.events.pipe(filter((e) => e instanceof NavigationEnd))
    .subscribe((s: NavigationEnd) => {
      this.checkIfIrregularitiesActive();
    });
  }

  get isLoggedIn(): boolean {
    return this.authService.loggedIn;
  }

  setLanguage(language: any): void {
    this.translateService.use(language.code);
  }

  toggleMobileMenu(): void {
    this.mobileMenuOpen =  !this.mobileMenuOpen;
  }

  closeMobileMenu(): void {
    this.mobileMenuOpen =  false;
  }

  selectElectionYear(year) {
    this.electionYear = year;
  }

  setElectionYear() {
    this.route.queryParams.subscribe(params => {
      this.electionYear = params.year || '2024';
    });
  }

  checkIfIrregularitiesActive() {
    if((window.location.href.toString()).includes('nepravilnosti')) {
      this.irregularitiesActive = true;
    } else {
      this.irregularitiesActive = false;
    }
  }

}
