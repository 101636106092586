<div class="static-page-wrapper">

    <mat-card class="static-page">
      <mat-card-title>Politika privatnosti</mat-card-title>
      <mat-card-content>
    
        <h3>DISKLEJMER</h3>

        <p>Pokret 1 od 5 miliona (“nama”, “mi” ili “naš”) rukovodi web aplikacijom
          <a href="https://ferislobodniizbori.rs/" target="_blank"> https://ferislobodniizbori.rs/</a>.
        </p>
        <p>Ova stranica Vam objašnjava našu politiku privatnosti koja se tiče skupljanja i korišćenja ličnih podataka
          prilikom prijave za kontrolu izbora.</p>
        <p>Mi koristimo Vaše podatke, koje sa nama podelite (lokaciju, ime, prezime, e-mail adresu, telefon), kako bismo omogućili prijavu izbornih nepravilnosti.<br />
          Korišćenjem <a href="https://ferislobodniizbori.rs/" target="_blank"> https://ferislobodniizbori.rs/</a> aplikacije za prijavu izbornih nepravilnosti pristajete na skupljanje i korišćenje informacija u
          skladu sa
          ovom politikom.</p>

        <h3>PROVAJDERI USLUGA</h3>

        <h4>Provajder hosting usluga</h4>
        <p>Mi koristimo hosting usluge koje je omogućio: Loopia - njihovu politiku privatnosti možete pogledati ovde:
          <a href="https://www.loopia.rs/loopia/zastita-podataka/" target="_blank">
            https://www.loopia.rs/loopia/zastita-podataka/</a>
        </p>

        <h4>Analitika</h4>
        <p>Možemo koristiti treća lica - Provajdere Usluga- kako bismo nadgledali i analizirali korišćenje naših
          Usluga.</p>
        <h4>Google Analytics</h4>
        <p>Google Analytics je Google-ov analitički internet servis koji prati i izveštava o internet
          saobraćaju.<br />
          Google
          koristi ove podatke da prati i nadgleda korišćenje web aplikacije kontrolor.rs. <br />Ovi podaci se dele sa
          drugim
          Google
          servisima. Google može da iskoristi prikupljene podatke da kontekstualizuje i personalizuje oglase na
          sopstvenoj
          reklamnoj mreži. Možete da izaberete da Vaša aktivnost ne bude dostupna Google Analytics-u tako što ćete
          instalirati
          Google Analytics opt-out dodatak za pretraživač. Ovaj dodatak sprečava Google Analytics JavaScript (ga.js,
          analytics.js, i dc.js) da deli informacije o posetama sa Google Analytics-om. Za više informacija o
          privatnosti na
          Google-u posetite njihovu stranicu Privatnost i Uslovi korišćenja: <a
            href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a></p>

        <h4>Facebook</h4>
        <p>Facebook remarketing servis je omogućio Facebook Inc. Da saznate više o Facebook-ovim oglasima baziranim na
          interesovanju posetite njihovu internet stranicu: <a href="https://www.facebook.com/help/164968693837950"
            target="_blank">https://www.facebook.com/help/164968693837950</a>
          Facebook se drži samo regulatornih Principa za Onlajn Bihevioralno Oglašavanje (Self-Regulatory Principles
          for
          Online Behavioral Advertising) ustanovljenih od strane Saveza za Digitalno Oglašavanje (Digital Advertising
          Alliance). Možete da se isključite sa Facebook-a i drugih učestvujućih kompanija preko Saveza za Digitalno
          Reklamiranje u SAD-u: <a href="http://optout.aboutads.info" target="_blank">http://optout.aboutads.info</a>,
          preko Saveza za
          Digitalno Oglašavanje Kanade u Kanadi:
          <a href="https://youradchoices.ca/" target="_blank">https://youradchoices.ca/</a> ili preko Evropskog Saveza
          za Interaktivno
          Digitalno Oglašavanje:
          <a href="http://www.youronlinechoices.eu/" target="_blank">http://www.youronlinechoices.eu/</a>, a možete se
          isključiti i
          preko podešavanja na Vašoj mobilnoj napravi. Za
          više
          informacija o Facebook-ovoj politici privatnosti posetite Facebook-ovu Politiku Podataka:
          <a href="https://www.facebook.com/privacy/explanation"
            target="_blank">https://www.facebook.com/privacy/explanation</a>
        </p>

        <h3>DEFINICIJE</h3>
        <p>Lični Podaci - Lični Podaci su podaci o živoj osobi koja se može identifikovati preko tih podataka (ili
          preko njih
          i drugih informacija koje ili imamo ili ćemo imati).</p>
        <p>Kolačići - Kolačići su malobrojni podaci koji se čuvaju na računaru (napravi) Korisnika.
          <br />
          Kontrolor Podataka - Kontrolor Podataka je osoba koja (bilo sama ili u saradnji ili zajedno sa drugim
          osobama)
          procenjuje svrhu i način na koji su ili će biti obrađeni bilo kakvi lični podaci. U svrhu ove Politike
          Privatnosti,
          mi smo Kontrolori Podataka za Vaše podatke.
          <br />
          Obrađivač Podataka (ili Provajder Usluga) - Obrađivač Podataka (ili Provajder Usluga) je bilo koja osoba
          (osim
          zaposlenog kod Kontrolora Podataka) koja obrađuje podatke u ime Kontrolora Podataka. Mi možemo koristiti
          usluge
          različitih Provajdera Usluga kako bismo efikasnije obradili vaše podatke.
          <br />
          Subjekat Podataka - Subjekat Podataka je bilo koja živa osoba koja je subjekat Ličnih Podataka.
          <br />
          Korisnik - Korisnik je pojedinac koji koristi web aplikaciju kontrolor.rs . Korisnik se odnosi na Subjekat
          Podataka,
          koji je objekat Ličnih Podataka.
        </p>

        <h3>'DO NOT TRACK' ('NE PRATI') SIGNALI</h3>
        <p>Naš sajt ne podržava “Ne Prati” (“DNT”). Ne Prati je opcija koju Vi podešavate u svom pretraživaču kako
          biste
          obavestili sajtove da ne želite da budete praćeni.</p>
        <p>Možete uključiti ili isključiti Ne Prati na stranici Karakteristike ili Podešavanja u Vašem pretraživaču.
        </p>
        <h3>VAŠA PRAVA</h3>
        <p>Ako želite da Vas obavestimo koji Lični Podaci o Vama se čuvaju kod nas i ako želite da ih uklonite iz
          sistema,
          molimo Vas da nas kontaktirate putem forme na sajtu Pokreta 1 od 5 miliona: <a
            href="https://1od5miliona.rs/kontakt/" target="_blank">https://1od5miliona.rs/kontakt/</a>
        </p>
        <p>U određenim okolnostima imate pravo:</p>
        <p>Da pristupite i primite kopiju Ličnih Podataka koje imamo o Vama<br />
          Da ispravite bilo kakve Lične Podatke o Vama koji nisu tačni<br />
          Da zahtevate brisanje Ličnih Podataka o Vama<br />
          Imate pravo na prenosivost podataka za informacije koje dajete Pokretu “1 od 5 miliona“. Možete da zahtevate
          da
          primite kopiju Vaših Ličnih Podataka u uobičajenom elektronskom formatu kako bi njome mogli da upravljate i
          da je
          premestite.
        </p>
        <p>Molimo Vas da obratite pažnju na to da ćemo Vam možda tražiti da potvrdite svoj identitet pre nego što
          odgovorimo na takve zahteve.</p>

        <h3>LINKOVI KA DRUGIM SAJTOVIMA</h3>
        <p>Naša Usluga može da sadrži linkove ka drugim sajtovima kojima ne rukovodimo mi. Ako kliknete na link trećeg
          lica,
          bićete upućeni na sajt trećeg lica. Savetujemo Vas da uvek pročitate Politiku Privatnosti svakog sajta koji
          posećujete.
        </p>
        <p>Nemamo kontrolu i ne preuzimamo odgovornost za sadržaj, politike privatnosti ili delanja bilo kojih sajtova
          i usluga trećih lica.</p>
        <h3>PRIVATNOST DECE</h3>
        <p>Naša usluga se ne odnosi na one koji imaju ispod 18 godina (“Deca”).</p>
        <p>Ne prikupljamo sa namerom informacije preko kojih se mogu identifikovati pojedninci mlađi od 18 godina.
          <br />Ako
          ste
          roditelj ili staratelj i saznate da nam je Vaše Dete poslalo lične podatke, molimo Vas da nas
          kontaktirate.<br />
          Ako
          saznamo da smo prikupili Lične Podatke dece bez saglasnosti roditelja, preduzećemo sve neophodne korake kako
          bismo
          uklonili te informacije sa naših servera.
        </p>

        <h3>PROMENE OVE POLITIKE PRIVATNOSTI</h3>
        <p>Povremeno možemo da ažuriramo ovu Politiku Privatnosti. Obavestićemo Vas o bilo kakvim promenama
          postavljanjem nove
          Politike Privatnosti na ovu stranicu.
          <br />
          Obavestićemo Vas putem imejla (ako ste se pretplatili na naša obaveštenja) i/ili putem naglašene objave na
          našoj
          Usluzi, pre nego što promena bude primenjena i ažuriraćemo “datum primene” na vrhu ove Politike Privatnosti.
          <br />
          Savetujemo Vas da povremeno pročitate ovu Politiku Privatnosti kako bi proverili da li je došlo do nekih
          promena.
          Promene u ovoj Politici Privatnosti su na snazi kada su postavljene na ovu stranicu.
        </p>
    
      </mat-card-content>
    </mat-card>
    
    
    </div>
    